import { useEffect, useState, useContext } from "react";
import { Button } from "../../common/Button";
import axios from "axios";
import { FlashContext } from '../../flash-context';
import { PricingTier } from "../../models/pricingTier";
import { Availability } from "../../models/availability";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPenToSquare, faCirclePlus, faFloppyDisk, faBan } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import Table from "../../common/Table";
import { MaterialAttribute } from "../../models/machineAttribute";


export default function MaterialAdditionalFields() {
  const { flash, setFlash } = useContext(FlashContext);

  // const flashValue = { flash, setFlash };
  const [pricingTiers, setPricingTiers] = useState([])
  const [availabilities, setAvailabilities] = useState([])
  const [attributes, setAttributes] = useState([])
  const [create, setCreate] = useState(false)
  const [edit, setEdit] = useState(false)
  const [newName, setNewName] = useState('')
  const [newDescription, setNewDescription] = useState('')
  const [type, setType] = useState(false)


  const getPricingTiers = () => {
    PricingTier.all().then((response) => {
      if (response.response) {
        console.log(response);
      } else {
        // console.log(response);
        setPricingTiers(response.data);
      }
    });
  }

  const getAvailabilities = () => {
    Availability.all().then((response) => {
      if (response.response) {
        console.log(response);
      } else {
        // console.log(response);
        setAvailabilities(response.data);
      }
    });
  }

  const getAttributes = () => {
    MaterialAttribute.all().then((response) => {
      if (response.response) {
        console.log(response);
      } else {
        // console.log(response);
        setAttributes(response.data);
      }
    });
  }

  const getAllData = () => {
    getPricingTiers();
    getAvailabilities();
    getAttributes();
  }

  const reset = () => {
    getAllData();
    handleCancel();
  }

  useEffect(() => {
    getAllData();
  }, [])

  const handleSave = () => {
    const form = new FormData();
    let url = type;
    let method = create ? "POST" : "PUT";
    if (type !== "availabilities") {
      form.append("description", newDescription);
    }
    if (edit) {
      url += `/${edit}`;
    }
    form.append("name", newName);

    const options = {
      method: method,
      url: url,
      headers: {
        'Content-Type': 'multipart/form-data',
        'enctype': 'multipart/form-data'
      },
      data: form
    };

    axios.request(options).then(function (response) {
      if (response.data.error) {
        console.log(response.data);
      } else {
        reset();
        setFlash({ type: 'success', message: "Successfully added!" });
      }
    }).catch(function (error) {
      console.error(error);
    });
  }

  const handleDelete = (url, name) => {
    if (window.confirm(`Are you sure you want to delete ${name}?`)) {
      const options = {
        method: "DELETE",
        url: url,
        headers: {
          'Content-Type': 'multipart/form-data',
          'enctype': 'multipart/form-data'
        }
      };

      axios.request(options).then(function (response) {
        if (response.data.error) {
          console.log(response.data);
        } else {
          reset();
          setFlash({ type: 'success', message: "Successfully deleted!" });
        }
      }).catch(function (error) {
        console.error(error);
      });
    }
  }

  const handleCancel = () => {
    setCreate(false);
    setEdit(false);
    setType(false);
    setNewName('');
    setNewDescription('');
  }

  const nameForm = () => {
    return <div className="mb-3">
      <label htmlFor="pricingTier" className="form-label">Name</label>
      <input onChange={e => setNewName(e.target.value)} data-cy="input-name" type="text" className="form-control" id="pricingTier" aria-describedby="pricingTier" placeholder="A short descriptive name to identify this pricing tier." defaultValue={newName} />
    </div>
  }

  const descriptionForm = () => {
    return <div className="mb-3">
      <label htmlFor="pricingTier" className="form-label">Description</label>
      <textarea onChange={e => setNewDescription(e.target.value)} data-cy="input-desc" rows="3" className="form-control" id="pricingTier" aria-describedby="pricingTier" placeholder="A short description to identify this pricing tier." defaultValue={newDescription} ></textarea>
    </div>
  }

  const setEditFields = (type, field) => {
    setEdit(field.id);
    setNewName(field.name);
    setNewDescription(field.description);
    setType(type);
  }

  const showTableFields = (fieldType) => {
    let data;
    let showData = [];
    if (fieldType === "pricing_tiers"){
        data = pricingTiers;
    } else if (fieldType === "availabilities") {
        data = availabilities;
    } else if (fieldType === "material_attributes") {
        data = attributes
    }
    data.map(d => {
      let row = {};
      if (edit === d.id && type === fieldType) {
        row.name = nameForm();
        if (fieldType === "pricing_tiers" || fieldType === "material_attributes") {
          row.description = descriptionForm();
        }
        row.action = saveAndCancel();
      } else {
        row.name = d.name;
        if (fieldType === "pricing_tiers" || fieldType === "material_attributes") {
          row.description = d.description;
        }
        row.action = <>
          <div className="tooltip-container" data-cy="edit-icon">
            <FontAwesomeIcon icon={faPenToSquare} onClick={() => setEditFields(fieldType, d)} />
            <span className="tooltip-text">Edit</span>
          </div>{'         '}
          <div className="tooltip-container" data-cy="delete-icon">
            <FontAwesomeIcon icon={faTrash} onClick={() => handleDelete(`${fieldType}/${d.id}`, d.name)} />
            <span className="tooltip-text">Delete</span>
          </div>
        </>
      }
      showData.push(row);
    })
    return showData;
  }

  const createField = (fieldType) => {
    return create && type === fieldType ?
      <>
        {nameForm()}
        {fieldType === "availabilities" ? '' : descriptionForm()}
        <Button className="btn btn-primary" dataCy="save-button" type="button" label="Save" onClick={handleSave} />{'         '}
        <Button className="btn btn-primary" dataCy="cancel-button" type="button" label="Cancel" onClick={handleCancel} />
      </> :
      <div className="tooltip-container" data-cy="add-new-field" onClick={() => { setCreate(true); setType(fieldType) }}>
        <FontAwesomeIcon icon={faCirclePlus} />
        <span>{' '}Add New</span>
        <span className="tooltip-text">Add</span>
      </div>
  }

  const saveAndCancel = () => {
    return <>
      <div className="tooltip-container">
        <FontAwesomeIcon icon={faFloppyDisk} data-cy="save-icon" onClick={handleSave} />
        <span className="tooltip-text">Save</span>
      </div>{'         '}
      <div className="tooltip-container">
        <FontAwesomeIcon icon={faBan} data-cy="cancel-icon" onClick={handleCancel} />
        <span className="tooltip-text">Cancel</span>
      </div>
    </>
  }

  return <>
    <Link to="../materials">
      <span className="btn btn-primary admin-addon-btn">Back to Materials</span>
    </Link>
    <h1>Material Attributes</h1>
    {createField("material_attributes")}
    <Table dataCy="material-attributes-table" colName={["Name", "Description", "Action"]} data={showTableFields("material_attributes")} />
    <h1>Material Pricing Tiers</h1>
    {createField("pricing_tiers")}
    <Table dataCy="pricing-tiers-table" colName={["Name", "Description", "Action"]} data={showTableFields("pricing_tiers")} />
    <h1>Material Availabilities</h1>
    {createField("availabilities")}
    <Table dataCy="availabilities-table" colName={["Name", "Action"]} data={showTableFields("availabilities")} />
  </>
}