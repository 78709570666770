import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Blurb } from "../../models/blurb";
import ContentForm from "./ContentForm";

export default function ViewContent() {
    const { id } = useParams();
    const [blurb, setBlurb] = useState({});

    const getContent = () => {
        Blurb.get(id).then((response) => {
            if (response.response) {
                console.log(response);
            } else {
                console.log(response);
                setBlurb(response.data);
            }
        });
    }

    useEffect(() => {
        getContent();
    }, [])

    return <>
        <h1>View Content</h1>
        <ContentForm method="PUT" url={`blurbs/${id}`} edit={true}
            blurb={blurb} />
    </>
}