import { Link, Outlet } from 'react-router-dom';
import { Button } from '../common/Button';
import { loginWithDuke, logoutWithDuke } from '../helpers';
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../user-context";
import Flash from '../common/Flash';
import './user.css';
import logo from '../assets/illustrations/logobluesmith_white.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { Blurb } from '../models/blurb';


export default function Navbar() {
    const { user, setUser } = useContext(UserContext);
    const [showNav, setShowNav] = useState(false);
    const [announcement, setAnnouncement] = useState(false);

    const getAnnouncement = () => {
      let blurb = new Blurb();
      blurb.getName("announcements").then((response) => {
        if (response.response) {
            console.log(response);
        } else {
            let announcement = response.data?.data[0];
            if (announcement?.active) {
                setAnnouncement(announcement.content);
            }
        }
      });
    }

    useEffect(() => {
        getAnnouncement();
    }, [])

    const authBtn = () => {
        if (user) {
            return <div><span className="float-right">{user.displayName} </span>
                <Button className="btn btn-outline-success" onClick={logoutWithDuke} label="Logout" />
            </div>
        } else {
            return <Button className="btn btn-outline-success" onClick={loginWithDuke} label="Login" />
        }
    }

    const showJob = () => {
        return <li className="nav-item">
            <Link className="nav-link active" aria-current="page" to="jobs" data-cy="nav-my-job">My Jobs</Link>
        </li>
    }

    const showAdmin = () => {
        return <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="/admin">Admin</a>
        </li>
    }

    const showAnnouncement = () => {
        return <div className="alert alert-warning d-flex align-items-center" id="announcement" role="alert">
            <span><FontAwesomeIcon icon={faCircleExclamation}/>{` ${announcement}`}</span>
            <button onClick={() => setAnnouncement(false)} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    }
    const showEmployee = () => {
        return <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="/employee">Employee</a>
        </li>
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg dukeBlue">
                <div>
                    <div className="logoToggle">
                    <Link className="navbar-brand" to="/">
                        <img
							src={logo}
							alt='bluesmith logo'
							className='iconLogo'
						/>
                    </Link>
                    <button className="navbar-toggler" type="button" onClick={() => setShowNav(!showNav)} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <FontAwesomeIcon icon={faBars}/>
                        
                    </button>
                    </div>
                    <div className={showNav? "collapse navbar-collapse show":"collapse navbar-collapse"} id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="services">What We Do</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="capabilities">Capabilities</Link>
                            </li>
                            {user ? showJob():''}
                            {user && user.type === "Admin" ? showAdmin():''}
                            {user && user.type === "Employee" ? showAdmin():''}
                        </ul>


                        {authBtn()}
                    </div>
                </div>
            </nav>

            {announcement? showAnnouncement():''}
            <Flash />
            <Outlet />

        </>

    )
}