import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { FlashContext } from "../flash-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import Table from "./Table";

export default function OldMachineMaterialTable(props) {
    const [machineMaterials, setMachineMaterials] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [printers, setPrinters] = useState([]);
    const { flash, setFlash } = useContext(FlashContext);

    useEffect(() => {
        getMaterials();
    }, [])

    useEffect(() => {
        if (props.job.machine_material_combos) {
            setMachineMaterials(props.job.machine_material_combos);
            machineMaterialForMetrics();
            getPrinters(props.job.machine_material_combos.map(mm => mm.machine_id));
        } else {
            getPrinters([]);
        }
    }, [props.job])

    const getPrinters = (machineMaterialIds) => {
        axios.get('machines').then(function (response) {
            setPrinters(response.data.filter(machine => machine.availability == true || machineMaterialIds.includes(machine.id)));
        }).catch(function (error) {
            console.error(error);
        }, printers);
    }

    const getMaterials = () => {
        axios.get('materials').then(function (response) {
            // console.log(response.data)
            setMaterials(response.data);
        }).catch(function (error) {
            console.error(error);
        }, printers);
    }

    // return machines and materials separated by | for metrics team
    const machineMaterialForMetrics = () => {
        const form = new FormData();
        let combo = props.job.machine_material_combos;
        if (combo?.length > 0) {
            let machines = [];
            let materials = [];
            combo.map(c => {
                if (c.machine_name) { machines.push(c.machine_name)}
                if (c.material_name) {materials.push(c.material_name)}
            })
            form.append("machines", machines.join("|"));
            form.append("materials", materials.join("|"));
            
            const options = {
                method: "GET",
                url: `jobs/${props.job.id}/`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'enctype': 'multipart/form-data'
                },
                data: form
            };

            axios.request(options).then(function (response) {
                if (response.data.error) {
                    console.log(response.data);
                } else {
                    // setFlash({ type: "success", message: "Updated For Metrics!" });
                }
            }).catch(function (error) {
                console.error(error);
            });
        }
    }

    const updateJobMachineMaterial = (combo) => {
        let method = "PUT";
        let url = `machine_material_combos/${combo.id}`;
        const form = new FormData();
        if (combo.id === -1) {
            method = "POST";
            url = "machine_material_combos/";
            form.append("bs_job_id", props.job.id);
        }

        console.log("job id: ", props.job.id);
        console.log("machine id: ", combo.machine_id);
        console.log("material id: ", combo.material_id);
        form.append("machine_id", combo.machine_id);
        form.append("material_id", combo.material_id);

        const options = {
            method: method,
            url: url,
            headers: {
                'Content-Type': 'multipart/form-data',
                'enctype': 'multipart/form-data'
            },
            data: form
        };

        axios.request(options).then(function (response) {
            if (response.data.error) {
                console.log(response.data);
            } else {
                setFlash({ type: "success", message: "Updated combo!" });
                props.getJob();
            }
        }).catch(function (error) {
            console.error(error);
        });
    }

    const deleteMachineMaterials = (idValue) => {
        if (window.confirm("Are you sure you want to delete this combo?")) {
            const options = {
                method: "DELETE",
                url: `machine_material_combos/${idValue}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'enctype': 'multipart/form-data'
                }
            };

            axios.request(options).then(function (response) {
                if (response.data.error) {
                    console.log(response.data);
                } else {
                    props.getJob();
                    setFlash({ type: "success", message: "Deleted combo!" });
                }
            }).catch(function (error) {
                console.error(error);
            });
        }
    }

    const handleUpdate = (e, type, row) => {
        let value = e.target.value;
        let combo = row;
        if (type === "machine_id") {
            combo.material_id = '';
        }
        combo[type] = value;
        updateJobMachineMaterial(combo);
    }

    const jobPrinter = (row) => {
        return <select onChange={e => handleUpdate(e, "machine_id", row)} className="form-select" aria-label="printer" id="printer" value={row?.machine_id || ''}>
            <option value=''>Let Consultant Select for me</option>
            {printers.map((p, index) => <option value={p.id} key={p.name + index}>{p.name}</option>)}
        </select>
    }

    const jobMaterial = (row) => {
        let showMaterials = materials;
        if (row.machine_id) {
            let p = printers.find((ps) => ps.id === row.machine_id);
            showMaterials = p?.materials;
        }
        return <select onChange={e => handleUpdate(e, "material_id", row)} className="form-select" aria-label="material" id="material" value={row?.material_id || ''}>
            <option value=''>Let Consultant Select for me</option>
            {showMaterials?.map((m, index) => <option value={m.id} key={m.name + index}>{m.name}</option>)}
        </select>
    }

    const addMachineMaterial = () => {
        updateJobMachineMaterial({ id: -1 });
    }

    const addMachineMaterialBtn = <FontAwesomeIcon icon={faCirclePlus} onClick={addMachineMaterial} />;

    // we were running into two race conditions with this one
    // initailly I(Anni) used useEffect but the printers and materials were not generated in time
    // so we decide to manually add this function to generate printer and material
    const itemsLoaded = () => {
        const printersPresent = printers.length > 0;
        const materialsPresent = materials.length > 0;
        const comboRowsPresent = machineMaterials.length > 0;
        const readyToLoad = printersPresent && materialsPresent && comboRowsPresent;
        let colNames = ["Printer", "Material", addMachineMaterialBtn];
        let showMachineMaterials = [];
        if (readyToLoad) {
            machineMaterials.map(i => {
                showMachineMaterials.push({
                    "printer": jobPrinter(i),
                    "material": jobMaterial(i),
                    "action": <FontAwesomeIcon icon={faTrash} data-cy="view-job-delete-machine-material" onClick={() => deleteMachineMaterials(i.id)} />
                })
            })
        }
        return <Table colName={colNames} data={showMachineMaterials} />
    }

    return <>
        {itemsLoaded()}
    </>
}