import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import ErrorImage from './assets/illustrations/404.svg';


export default function NotFoundPage() {
    return <div data-cy="not-found-page" className="not-found container-fluid text-center">
        <div className="row">
            <div className="col-sm-12">
                <h1>
                    Oops! Looks like you followed a bad link or you're not logged in.
                </h1>
                <Link to="/" className="link-button" data-cy="back-to-home-link">
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <span>Back to Home</span>
                </Link>
            </div>
            <div className="col w-75">
                <img src={ErrorImage} alt="404, page not found" />
            </div>
        </div>
    </div>
}