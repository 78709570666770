import { ApiCore } from '../service/core';
import { apiProvider } from '../service/provider';

export class Payment extends ApiCore {
	constructor(attrs) {
		const options = { basePath: 'payments' };
		super(options);
		this.attrs = attrs;
	}
	// static get functions must not be arrow functions
	static get basePath() {
		return 'payments';
	}

	static getFundCodeData = (fundCode) => {
		return apiProvider.get(`payments/validate?fund_code=${fundCode}`);
	}

	static fundCodePayment = (jobId, fundCode) => {
		return apiProvider.get(`payments/${jobId}/fund_code?fund_code=${fundCode}`)
	}

	static getJobAttrs = (jobId) => {
		return apiProvider.get(`payments/${jobId}/attrs`);
	}
}