import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Footer from './user/Footer';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'
import reportWebVitals from './reportWebVitals';

//axios global config
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;






const root = ReactDOM.createRoot(document.getElementById('root'));
const footer = ReactDOM.createRoot(document.getElementById('footer'));
root.render(
      <App />
);
footer.render(
      <Footer />
);



//IMPORTANT this strict mode causes many things to get rendered twice and only runs in dev
// this is the behavior out of the box, I disabled as it was causing issues when developing cc payments causing multiple payment submissions
// https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
// https://stackoverflow.com/questions/61254372/my-react-component-is-rendering-twice-because-of-strict-mode
// https://www.knowledgehut.com/blog/web-development/react-strict-mode
// root.render(
//   <React.StrictMode>
//       <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
