// provider.js
// this file defines your api url from any source.
// Pulling from your .env file when on the server or from localhost when locally

import axios from 'axios';

// @param {string} resource, such as 'departments'
const get = (resource) => {
	return axios
		.get(resource)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
};

// @param {string} resource
// @param {object} model

const post = (resource, model) => {
	return axios
		.post(resource, model)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
};

// @param {string} resource
// @param {object} model

const put = (resource, model) => {
	return axios
		.put(resource, model)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
};

// @param {string} resource
// @param {object} model
const patch = (resource, model) => {
	return axios
		.patch(resource, model)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
};

// @param {string} resource
const remove = (resource) => {
	return axios
		.delete(resource)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
};

export const apiProvider = {
	get,
	post,
	put,
	patch,
	remove
};
