import { useEffect, useState } from "react";
import axios from "axios";
import BsDataTable from '../common/BsDataTable';
import { Link } from "react-router-dom";
import { formatJobState } from "../helpers";
import { Job } from "../models/job";

export default function AdminJobsPage() {
  const [jobData, setJobData] = useState([]);
  const [filteredJobData, setFilteredJobData] = useState([]);

  const [url, setUrl] = useState();
  const [header, setHeader] = useState(false);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const limit = 15;

  
  useEffect(() => {
    checkURL();
    setOffset(0);
  }, [window.location.pathname])

  useEffect(() => {
      getJobs();
  }, [offset, url])

  const getJobs = () => {
    if(!url){
      return
    }else{
    const job = new Job();
    job.filter(url).then((response) => {
      if (response.response) {
          console.log(response);
      } else {
        console.log(response);
        if (url == "pending_staff" || url == "pending_client") {
          response.data.forEach(row => {
            // changing the name of the status for all jobs that have been picked up and paid
            row["state"] = row.picked_up && row.paid ? "completed":formatJobState(row)
            row["viewBtn"] = <Link className="btn btn-primary position-relative mt-3" label="View" to={`/admin/${row.id}`}> 
              View
              {row.unread_messages == 0 ? ('') : (
                <span class="position-absolute top-0 translate-middle badge rounded-pill bg-danger">
                  {row.unread_messages}
                  <span class="visually-hidden">unread messages</span>
                </span>
              )}
            </Link> 
            row["assigned_to"] = displayStaffers(row.staffers)
          })
        } else {
          response.data.forEach(row => {
            // changing the name of the status for all jobs that have been picked up and paid
            row["state"] = row.picked_up && row.paid ? "completed":formatJobState(row)
            row["viewBtn"] = <Link className="btn btn-primary" label="View" to={`/admin/${row.id}`}>View</Link> 
            row["assigned_to"] = displayStaffers(row.staffers)
          })
        }

        setJobData(response.data);
        setFilteredJobData(response.data);
        console.log(response.data);
      }
    });

    }
    
  }

  const displayStaffers = (staffers) => {
    if (staffers.length > 0){
      // return staffers.map((r, index) => <span className="badge text-bg-primary" key={index} data-bs-toggle="tooltip" data-bs-title="Default tooltip">{r.display_name}</span>);
      return staffers.map((r, i) => [
        i > 0 && ", ",
        r.display_name]);
      // return staffers.map((r, index) => r.display_name);
    } else {
      return "-";
    }
  }


  const checkURL = () => {
    let pathname = window.location.pathname;
    if (pathname === "/admin") {
      setUrl(`active`);
      setHeader("Active Jobs");
    }
    if (pathname === "/admin/pending_staff") {
      setUrl(`pending_staff`);
      setHeader("Jobs Pending Staff");
    }
    if (pathname === "/admin/pending_client") {
      setUrl(`pending_client`);
      setHeader("Jobs Pending Client");
    }
    if (pathname === "/admin/completed") {
      setUrl(`complete`);
      setHeader("Completed Jobs");
    }
    if (pathname === "/admin/all") {
      setUrl(`all`);
      setHeader("All Jobs");
    }
    if (pathname === "/employee") {
      setUrl(`active`);
      setHeader("Active Jobs");
    }
    if (pathname === "/employee/pending_staff") {
      setUrl(`pending_staff`);
      setHeader("Jobs Pending Staff");
    }
    if (pathname === "/employee/pending_client") {
      setUrl(`pending_client`);
      setHeader("Jobs Pending Client");
    }
    if (pathname === "/employee/completed") {
      setUrl(`complete`);
      setHeader("Completed Jobs");
    }
    if (pathname === "/employee/all") {
      setUrl(`all`);
      setHeader("All Jobs");
    }
  }

  const showTable = () => {
    // the key is the display you want on the table and the value is the key for the data returned from the api
    const columnSettings = {
      '#': 'id',
      'Name': 'name',
      'Client': 'users',
      'Days Active': 'days_active',
      'Last Touched': 'last_touched',
      'Start Date': 'pretty_created_at',
      'Assigned to': 'assigned_to',
      'Status': 'state',
      'Actions': 'viewBtn'
    }



    return <BsDataTable
      setFilteredData={setFilteredJobData} 
      columnSettings={columnSettings} 
      filteredData={filteredJobData} 
      originalData={jobData}
      csvFilename="bluesmith-jobs.csv"
    />
  }

  return <>
    <h1>{header}</h1>
    {showTable()}
  </>
}