
import { ApiCore } from '../service/core';

export class MaterialAttribute extends ApiCore {
	constructor(attrs) {
		const options = { basePath: 'material_attributes' };
		super(options);
		this.attrs = attrs;
	}
	// static get functions must not be arrow functions
	static get basePath() {
		return 'material_attributes';
	}
}