import { apiProvider } from './provider';

export class ApiCore {
	constructor(options) {
		this.options = options;
		this.basePath = this.options.basePath;
	}

	// static getter function must not be arrow functions
	// see department get basePath for example
	static all() {
		return apiProvider.get(this.basePath);
	}

	static get(id) {
		return apiProvider.get(`${this.basePath}/${id}`);
	}

	static filter(identifier, value) {
		return apiProvider.get(`${this.basePath}?${identifier}=${value}`);
	}

	static create(attrs) {
		return apiProvider.post(this.basePath, attrs);
	}

	// to support behavior consistent with ruby backend eg. ability to call
	// methods like before create
	save() {
		return apiProvider.post(this.basePath, this.attrs);
	}

	update(id) {
		return apiProvider.put(`${this.basePath}/${id}`, this.attrs);
	}

	patch(id) {
		return apiProvider.patch(`${this.basePath}/${id}`, this.attrs);
	}

	delete() {
		return apiProvider.remove(`${this.basePath}/${this.attrs.id}`);
	}
}
