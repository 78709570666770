import Table from "../common/Table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX, faCheck } from '@fortawesome/free-solid-svg-icons'

export default function AdminChecklist(props){
  const jobStates = ["Job submitted", "Estimate of charges sent to client", "Client approved estimate", "Job finished printing", "Job finished post-processing", "Invoice sent to client", "Payment submitted and complete", "Job placed at pick-up station", "Client has the print"];
  
  
  const calculateCheckBox = (isTrue ) => {
    if(isTrue){
      return completeCheck()
    }else{
      return incompleteCheck()
    }
  }

  //render the check list
  const checkList = () => {
    let steps = [];
    let index = 0;
    for (const [name, value] of Object.entries(props.stateCheck)) {
      const jobState = jobStates[index];
        steps.push(<p onClick={e => handleCheckboxClick(name, value)} className="bs-job-check-option">
                {calculateCheckBox(value)} Step {index} - {jobState}
                </p>
                );
        index++;
    }
    return steps
  }

  const completeCheck = () => {
    return <span className="bs-job-check" data-cy="admin-job-check"><FontAwesomeIcon  icon={faCheck} /> </span>
  }
  const incompleteCheck = () => {
    return <span data-cy="admin-job-uncheck"><FontAwesomeIcon className="bs-job-uncheck" icon={faX} /> </span>
  }
  // handle any logic associated with toggling a checkbox
  const handleCheckboxClick = (name, isChecked) => {
    if(name === "estimate"){
      alert("Estimate can not be toggled. Set and send estimate in the \"Costs\" tab. ")
    }
    else if(name === "paid"){
      alert("Payment can not be toggled. Client must actually pay, or an admin can pay on behalf of the client in the \"Costs\" tab. ")
    }
    else if(name === "cost"){
      alert("Invoice can not be toggled. Send an invoice in the \"Costs\" tab. ")
    }
    else if(name === "estimate_approved_by" && !isChecked){
        const confirm = window.confirm("Are you sure you want to approve this estimate on behalf of the client(this will be recorded)?") 
        if (!confirm){return}
        props.updateJob("estimate_approved_by", true)
        props.setInvoiceToMatchEstimate()
    }
    else if (name === "estimate_approved_by" && isChecked) {
          const confirm = window.confirm("By unchecking this, you will reset the estimate for this job. Are you sure?")  
          if(!confirm){return}
          props.updateJob("estimate", 0.0);
          props.updateJob("estimate_approved_by", false)
    }
    else{
      props.updateJob(name, !isChecked)
    }
  }

  return <div data-cy="admin-job-check-list">
    {checkList()}
  </div>
}