import { Link, Outlet } from "react-router-dom";
import { Button } from "../common/Button";
import Flash from "../common/Flash";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./admin.css"
import { useContext } from "react";
import { UserContext } from "../user-context";
import { logoutWithDuke } from "../helpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { faFolderClosed, faBullhorn } from '@fortawesome/free-solid-svg-icons'

export default function AdminNavbar() {
    const { user, setUser } = useContext(UserContext);
    console.log("user is: ", user);

    return (
        <div className="container-fluid admin-side">
                <div className="admin-nav">
                    <div>
                        <a href="/" className="back-link">
                            <i className="bi bi-arrow-right-circle"></i><span>Client Side</span>
                        </a>
                        <ul id="menu">
                            <li className="nav-item">
                                <Link to="pending_staff">
                                    <FontAwesomeIcon icon={faFolderClosed} />
                                    <span className="ms-1 d-none d-sm-inline">Pending Staff</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="pending_client">
                                    <FontAwesomeIcon icon={faFolderClosed} />
                                    <span className="ms-1 d-none d-sm-inline">Pending Client</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="">
                                <FontAwesomeIcon icon={faFolderClosed} />
                                <span className="ms-1 d-none d-sm-inline">Active Jobs</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="completed">
                                    <FontAwesomeIcon icon={faFolderClosed} />
                                    <span className="ms-1 d-none d-sm-inline">Completed Jobs</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="all">
                                  <FontAwesomeIcon icon={faFolderClosed} /> 
                                  <span className="ms-1 d-none d-sm-inline">All Jobs</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="users">
                                    <i className="bi bi-people-fill"></i> <span className="ms-1 d-none d-sm-inline">Users</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="printers">
                                    <i className="bi bi-hammer"></i> <span className="ms-1 d-none d-sm-inline">Machines</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="materials">
                                    <i className="bi bi-stars"></i> <span className="ms-1 d-none d-sm-inline">Materials</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="payment_history">
                                  <FontAwesomeIcon  icon={faMoneyBill} /> <span className="ms-1 d-none d-sm-inline">Payment History</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="content_editor">
                                  <FontAwesomeIcon  icon={faBullhorn} /> <span className="ms-1 d-none d-sm-inline">Content Editor</span>
                                </Link>
                            </li>
                        </ul>
                        <div className="admin-name">
                            <span>{user.displayName} </span>
                            <Button className="btn btn-outline-success" onClick={logoutWithDuke} label="Logout" />
                        </div>
                    </div>
                </div>
                <Flash />
                <div className="admin-main">
                    <Outlet />
                </div>
        </div>
    )
}