import { ApiCore } from '../service/core';
import { apiProvider } from '../service/provider';

export class Availability extends ApiCore {
	constructor(attrs) {
		const options = { basePath: 'availabilities' };
		super(options);
		this.attrs = attrs;
	}

	// static get functions must not be arrow functions
	static get basePath() {
		return 'availabilities';
	}
}