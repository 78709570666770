import { useContext } from "react";
import axios from "axios";
import ManageClients from "../../common/ManageClients";
import { FlashContext } from "../../flash-context";
import { Job } from "../../models/job";
import OldMachineMaterialTable from "../../common/OldMachineMaterialTable";
import MachineMaterialTable from "../../common/MachineMaterialTable";

export default function AdminDetailTab(props) {
    const { flash, setFlash } = useContext(FlashContext);

    const jobDeadline = () => {
        return <p>
            <strong>Preferred Deadline: </strong>
            <input onChange={e => handleDeadlineUpdate(e)} type="date" className="form-control" id="JobDeadline" defaultValue={props.job.deadline} />
        </p>
    }

    const handleDeadlineUpdate = (e) => {
        if (window.confirm("Are you sure you want to update?")) {
            const form = new FormData();
            form.append("deadline", e.target.value);
            const options = {
                method: 'PUT',
                url: `jobs/${props.job.id}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'enctype': 'multipart/form-data'
                },
                data: form
            };

            axios.request(options).then(function (response) {
                if (response.data.error) {
                    console.log(response.data.messages)
                } else {
                    setFlash({ type: "success", message: "You have updated the deadline." })
                }
            }).catch(function (error) {
                console.error(error);
            });
        }
    }

    const handleMachineMaterialUpdate = (selectedPrinter, selectedMaterials) => {
        const selectedMaterialIds = selectedMaterials.map((m) => m.value);
        let params = {'machine_id': selectedPrinter, 'material_ids': selectedMaterialIds};
        Job.updateMachineMaterialCombos(params, props.job.id).then(() => {
            props.getJob();
        });
    }

    const machineMaterialTable = () => {
        let uniqueMachineIds = [];
        let materialIds = [];
        props.job.machine_material_combos.forEach((mmc) => {
            materialIds.push({value: mmc.material_id, label: mmc.material_name});
            if (!uniqueMachineIds.includes(mmc.machine_id)) {
                uniqueMachineIds.push(mmc.machine_id);
            }
        })
        if (uniqueMachineIds.length <= 1) {
            return <MachineMaterialTable
                selectedPrinter={uniqueMachineIds[0] || 0}
                selectedMaterials={materialIds}
                handleMachineMaterialUpdate={handleMachineMaterialUpdate}
            />
        } else {
            return <OldMachineMaterialTable job={props.job} getJob={props.getJob} />
        }
    }

    return (
        <>
            <h3>Detail</h3>
            <p data-cy="job-description"><strong>Description: </strong>{props.job.description}</p>
            <p data-cy="job-application"><strong>Application: </strong>{props.job.application}</p>
            <p data-cy="job-start-date"><strong>Start Date: </strong>{props.job.pretty_created_at}</p>
            {jobDeadline()}
            {machineMaterialTable()}
            <p data-cy="job-consultation"><strong>Consultation: </strong>{props.job.wants_consultation ? "Yes" : "No"}</p>
            <p data-cy="job-clinical"><strong>Clinical Service: </strong>{props.job.is_clinical_service ? "Selected" : "Not Selected"}</p>
            <p data-cy="job-confidential"><strong>Confidential Service: </strong>{props.job.is_confidential ? "Selected" : "Not Selected"}</p>
            <p data-cy="job-design-hub"><strong>DesignHub Service: </strong>{props.job.is_design_hub ? "Selected" : "Not Selected"}</p>
            <p><strong>Project shareable in our promotional materials: </strong> {props.job.is_shareable ? "Yes" : "No"}</p>
            <p><strong>Course Number: </strong>{props.job.course_number === "false" ? "Not Applicatble" : props.job.course_number}</p>
            <h3>Clients:</h3>
            <ManageClients dataCy="table-collaborators" job={props.job} getJob={props.getJob} />
        </>
    )
}