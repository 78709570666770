
import { ApiCore } from '../service/core';
import { apiProvider } from '../service/provider';

export class Machine extends ApiCore {
	constructor(attrs) {
		const options = { basePath: 'machines' };
		super(options);
		this.attrs = attrs;
	}
	// static get functions must not be arrow functions
	static get basePath() {
		return 'machines';
	}

	static toggle_availability = (id) => {
		return apiProvider.put(
			`machines/${id}/toggle_availability`
		)
	}
}