import React, { useEffect, useState } from "react";
import '../admin/admin.css';

const Tabs = (props) => {
    // some tab titles use icon. to make sure icons can be compared, they're passed in as objects
    const [activeTab, setActiveTab] = useState(props.tabs[0].id || props.tabs[0]);
    const [tabContents, setTabContents] = useState(props.content);

    useEffect(() => {
        setTabContents(props.content);
    }, [props.content])

    return (
        <div className="Tabs" data-cy={props.dataCy}>
            <ul className="nav nav-tabs">
                {props.tabs.map((t, index) => {
                    return (<TabNavItem
                            key={index} title={t.title || t} id={t.id || t} activeTab={activeTab} setActiveTab={setActiveTab} />)
                })}
            </ul>

            <div className="outlet">
                {tabContents.map((c, index) => {
                    return (
                    <TabContent key={index} id={props.tabs[index].id || props.tabs[index]} activeTab={activeTab}>
                        {c}
                    </TabContent>)
                })}
            </div>
        </div>
    );
};
export default Tabs;

export const TabNavItem = ({ id, title, activeTab, setActiveTab }) => {

    const handleClick = () => {
        setActiveTab(id);
    };

    return (
        <li onClick={handleClick} className="nav-item" id = "hoverable_tab">
            <a className={activeTab === id ? "active nav-link" : "nav-link"}
                id = {activeTab === id ? "colored_tab" : "uncolored-tab"}>{title}</a>
        </li>
    );
};

export const TabContent = ({ id, activeTab, children }) => {
    return (
        activeTab === id ? <div className="TabContent">
            {children}
        </div>
            : null
    );
};