import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Button } from "../../common/Button";
import Table from "../../common/Table";
import { UserContext } from "../../user-context";

export default function AdminFileTab(props) {
    const [filenames, setFilenames] = useState([]);
    const [newFiles, setNewFiles] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const { user, setUser } = useContext(UserContext);
    const [inputChange, setInputChange] = useState(Date.now);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    //console.log(props.job)

    useEffect(() => {
        if (props.job.filenames) {
            let files = [];
            for (let f of props.job.filenames) {
                files.push({ 
                    [f.id]: <span>
                        <a href={`${process.env.REACT_APP_API_HOST}/jobs/${props.id}/download?fileid=${f.id}&filename=${encodeURIComponent(f.filename)}`} download>{f.filename}</a> 
                    </span>,
                    "quantity": <p>{f.quantity}</p>,
                    "delete": 
                        <Button 
                            className="btn btn-outline-secondary btn-remove" 
                            label= "x"
                            onClick={() => {
                                //console.log(f)
                                if (window.confirm("Are you sure you want to delete this file?")) {
                                    handleDeleteFile(f)
                                }}}
                        /> 
                })
            }
            setFilenames(files);
        }
    }, [props.job.filenames])

    const handleDeleteFile = (f) => {
        //console.log("File #" + f.id + " from job #" + props.job.id + " deleted by " + user.displayName)
        const form = new FormData();

        form.append("file_id", f.id)
        form.append("file_name", f.filename)
        form.append("user_name", user.displayName)
        form.append("job_id", props.id)
        form.append("job_name", props.job.name)
        form.append("unique_id", user.duid)

        const options = {
            method: "PUT",
            url: `jobs/${props.id}/delete_files`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'enctype': 'multipart/form-data'
            },
            data: form
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            if (response.data.error) {
                console.log(response.data)
            } else {
                props.getJob();
            }
        }).catch(function (error) {
            console.error(error);
        });
    }

    const uploadFiles = (name, value) => {
        //console.log(name);
        //console.log(value);
        setShowLoadingSpinner(true);
        const form = new FormData();

        Array.from(value).forEach(file => {
            form.append("job_files[]", file);
        });
        form.append("quantity", quantity);
        const options = {
            method: "PUT",
            url: `jobs/${props.id}/append_files`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'enctype': 'multipart/form-data'
            },
            data: form
        };

        if (value) {
            axios.request(options).then(function (response) {
                console.log(response.data);
                if (response.data.error) {
                    console.log(response.data)
                } else {
                    props.getJob();
                }
                setQuantity(1);
                setNewFiles(false);
                setShowLoadingSpinner(false);
            }).catch(function (error) {
                setShowLoadingSpinner(false);
                console.error(error);
            });
        }
    }

    return <>
        <h3>Files</h3>
        <div className="input-group">
            <input className="form-control" type="file" id="file" onChange={e => {setNewFiles(e.target.files)}}
                    key={inputChange} />
            <input onChange={e => {setQuantity(e.target.value)}} type="number" className="form-control" id="quantity" aria-describedby="file-quantity" required key={quantity} min={1} defaultValue={quantity} />
            <Button className="btn btn-outline-secondary" dataCy="admin-file-upload" label="Upload" 
                    onClick={e => {uploadFiles("file", newFiles); setInputChange(Date.now)}} multiple />
        </div>
        <Table dataCy="table-filenames" colName={["File Name", "Copies", "Delete"]} data={filenames} />
        {showLoadingSpinner ? (<div class="spinner-border text-primary" role="status"><span class="visually-hidden">Loading...</span></div>) : ('')}
    </>
}