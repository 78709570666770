import { Button } from "./Button";
import UserSearch from "./Search";
import Table from "./Table";
import { useContext, useEffect, useState } from "react";
import { FlashContext } from "../flash-context";
import { UserContext } from "../user-context";
import axios from "axios";
import { Job } from "../models/job";


export default function ManageClients(props) {
    const [clients, setClients] = useState([]);
    const [invite, setInvite] = useState([]);
    const { flash, setFlash } = useContext(FlashContext);
    const { user, setUser } = useContext(UserContext);
    console.log("user id is: ", user.id);
    
    
    useEffect(() => { addCollaboratorTags();}, [props.job.clients])
    
    // checking the url to know if we are on the admin side
    function onAdminSide() {
        var pageUrl = window.location.href;
        var result = pageUrl.match(/admin/g);
        return result;
    }

    const changePrimary = (selectedClient, thisJob) => {
        let job = new Job({ id: thisJob.id });
        job.updatePrimaryClient(selectedClient.id).then((response) => {
            if (response.response) {
                console.log(response.response)
            } else {
                props.getJob();
            }
        });
    }

    // function that add a tag ('Primary Requestor' and 'Collaborator')
    function addCollaboratorTags(){
        if (props.job.clients) {
            let clientList = [];
            let c = props.job.clients;
            let thisJob = props.job;
            let primaryClient = props.job.primary_client
            if (onAdminSide()){
                for (let i = 0; i < c.length; i++) {
                    if ([c[i].id] == primaryClient.id) {
                        clientList.unshift({ [c[i].id]: 
                        <div className="collab-table">
                            <span>
                                {c[i].email}
                            </span>
                            <span className="pill-span">primary requestor</span>
                        </div>
                        })
                    } else {
                        clientList.push({ [c[i].id]: 
                        <div className="collab-table">
                            <span>
                                {c[i].email}
                            </span>
                            <span className="pill-span">collaborator</span>
                        <Button 
                            className="btn btn-primary btn-smaller" 
                            dataCy="manage-client-primary-button"
                            label = "Make Primary" 
                            onClick={() => changePrimary(c[i], thisJob)}
                        />
                        <Button 
                            className="btn btn-outline-secondary btn-remove" 
                            dataCy="manage-client-uninvite-button"
                            label= "x"
                            onClick={() => handleUninvite(c[i])} 
                        />
                        </div> })
                    }
                }
            } else {
                for (let i = 0; i < c.length; i++) {
                    if ([c[i].id] == primaryClient?.id) {
                        clientList.unshift({ [c[i].id]: 
                        <div className="collab-table">
                            <span>
                                {c[i].email}
                            </span>
                            <span className="pill-span">primary requestor</span>
                        </div>
                        })
                    } else {
                        if (user.id == primaryClient.id){
                            clientList.push({ [c[i].id]: 
                                <div className="collab-table">
                                    <span>
                                        {c[i].email}
                                    </span>
                                    <span className="pill-span">collaborator</span>
                                {console.log("clientList is: ", clientList)}
                                <Button 
                                    className="btn btn-primary btn-smaller"
                                    dataCy="manage-client-primary-button" 
                                    label = "Make Primary" 
                                    onClick={() => changePrimary(c[i], thisJob)}
                                />
                                <Button 
                                    className="btn btn-outline-secondary btn-remove" 
                                    dataCy="manage-client-uninvite-button"
                                    label= "x"
                                    onClick={() => handleUninvite(c[i])} 
                                />
                                </div> })
                        } else{
                            clientList.push({ [c[i].id]: 
                                <div className="collab-table">
                                    <span>
                                        {c[i].email}
                                    </span>
                                    <span className="pill-span">collaborator</span>   
                                </div> 
                            })
                        }
                    }
                    console.log("clientList: ", clientList);
                }
            }
            console.log("the client array: ", clientList);
            setClients(clientList);
        }
    }

    const handleInvite = () => {
        console.log(invite);
        const form = new FormData();

        invite.forEach(i => {
            form.append("duids[]", i.value);
        })

        const options = {
            method: "PUT",
            url: `jobs/${props.job.id}/add_collaborator`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'enctype': 'multipart/form-data'
            },
            data: form
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            if (response.data.error) {
                setFlash({ type: "danger", message: response.data.message });
            } else {
                props.getJob();
                setFlash({ type: "success", message: "Invited!" });
                setInvite([]);
            }
        }).catch(function (error) {
            console.error(error);
        });
    }
    
    const handleUninvite = (clientId) => {
        console.log("CLIENT ID IN UNINVITE: ", clientId.id);
        const form = new FormData();
    
        form.append("client_id", clientId.id);
    // the error was coming from the fact that the append was only supposed to get the id but was given the whole object
        const options = {
            method: "PUT",
            url: `jobs/${props.job.id}/remove_collaborator`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'enctype': 'multipart/form-data'
            },
            data: form
        };
    
        axios.request(options).then(function (response) {
            console.log(response.data);
            if (response.data.error) {
                setFlash({ type: "danger", message: response.data.message });
            } else {
                props.getJob();
                setFlash({ type: "success", message: "Uninvited!" });
            }
        }).catch(function (error) {
            console.error(error);
        });
    }

    return <>
        <Table dataCy={props.dataCy} colName={["Emails"]} data={clients} />
        <div className="input-group mb-3">
            <UserSearch id="invite" handleChange={(e) => setInvite(e)} invite={invite} />
            <Button className="btn btn-outline-secondary" type="button" id="inviteEmail" label="Invite" onClick={handleInvite} />
        </div>
    </>
}