import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import PrinterForm from "./PrinterForm";
import { Machine } from "../../models/machine";

export default function ViewPrinter() {
    const { id } = useParams();
    const [printer, setPrinter] = useState({});
    const [defaultSelect, setDefaultSelect] = useState([]);

    const getPrinter = () => {
        Machine.get(id).then((response) => {
            if (response.response) {
                console.log(response);
            } else {
            console.log(response);
            let data = response.data;
            let selects = [];
            let ids = [];
            data.materials.map(m => {
                selects.push({ value: m.id, label: m.name });
                ids.push(m.id);
            })
            data.materials = ids;
            setDefaultSelect(selects);
            setPrinter(data);
            }
        });
    }

    useEffect(() => {
        getPrinter();
    }, [])

    return <>
        <h1>View Machine</h1>
        <PrinterForm method="PUT" url={`machines/${id}`} edit={true}
            printer={printer} defaultSelect={defaultSelect} />
    </>
}