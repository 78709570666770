import { useContext, useRef } from "react";
import { FlashContext } from "../flash-context";

export default function Flash() {
  const { flash, setFlash } = useContext(FlashContext);
  const myRef = useRef(null)

  const closeFlash = () => setFlash(false);

  const executeScroll = () => {
    myRef.current.scrollIntoView() 
  }   

  const setMessage = (message) => {
    if(Array.isArray(flash.message)){
      const msgArray = []
      message.forEach(m => msgArray.push(<p>* {m}</p>))
      return msgArray
    } else{
    return message
    }
  }

const globalFlash = () => {
  if(!flash){return};  executeScroll();
  const divClass = `alert alert-${flash?.type} d-flex align-items-center`
 
  return <div className={divClass} role="alert">
      <span>{setMessage(flash.message)}</span>
      <button onClick={closeFlash} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
 
}

  return (
    <>
        <div ref={myRef} data-cy="global-flash" className={flash?.message ? "container flash":"container flash hide-flash"} >
            {globalFlash()}
            <br/>
        </div>
    </>
  )
}