import { useNavigate } from "react-router-dom";
import { Button } from "../common/Button";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { UserContext } from "../user-context";
import { loginWithDuke } from "../helpers";
import services1 from '../assets/illustrations/bluesmith_services_01.svg';
import services2 from '../assets/illustrations/bluesmith_services_02.svg';

export default function ServicePage() {
    const { user, setUser } = useContext(UserContext);
    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/createJob`;
        navigate(path);
    }
    // const [printers, setPrinters] = useState([]);
    // const [show, setShow] = useState(false);

    // const getPrinters = () => {
    //     axios.get('/machines')
    //         .then(res => {
    //             let data = res.data.data;
    //             setPrinters(data);
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    // }

    // useEffect(() => {
    //     getPrinters();
    // }, [])

    // const handleClick = (e) => {
    //     let id = e.target.id;
    //     if (show === id) {
    //         id = false;
    //     }
    //     setShow(id);
    // }

    // const accordion = (p, index) => {
    //     return (
    //         <div className="accordion-item" key={"p" + index}>
    //             <h4 className="accordion-header" id={p.id}>
    //                 <button className={show === p.id + '' ? "accordion-button" : "accordion-button collapsed"} id={p.id} onClick={(e) => handleClick(e)} type="button" data-bs-toggle="collapse" data-bs-target={p.id} aria-expanded="false" aria-controls={p.id}>
    //                     <strong>{p.name}</strong>
    //                 </button>
    //             </h4>
    //             <div className={show === p.id + '' ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="p.id" data-bs-parent="#accordionExample">
    //                 <div className="accordion-body">
    //                     {/* {p.description} */}
    //                     <div dangerouslySetInnerHTML={{ __html: p.description }} />
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }
    return <div className="container wrapper services">
        <div className="main">
                <h1>Services and Pricing</h1>
                {user?<Button className="btn btn-primary submit-job" dataCy="submit-a-job" label="Submit a Job" onClick={routeChange} />:<Button className="btn btn-primary" dataCy="login" onClick={loginWithDuke} label="Login to Submit A Job" />}
                <div>
                    
                    <p className="lh-lg">The cost of 3D printing varies widely and is based on technology and the amount of material used.</p>
                    <p className="lh-lg">Self-service printing is available on our basic Ultimaker FDM printers in the CoLab Makerspace using a rainbow of PLA material. This option is accessed through duke.3dprinteros.com with more information located at the Innovation CoLab Studio page here. Self-service printing is first-come-first-served and without charge.
                        We also allow some limited self-service SLA printing through our reservation system. If you want to request a reservation on one of the SLA printers, please fill out the job request on our site as you would for a normal job and specify the machine and project use in the Job Description.</p>
                        <img
                        src={services1}
                        alt='services'
                        className='illustrations'
                    />
                    <p className="lh-lg">The Clinical Service option for clinical use cases includes prioritization on requested machines, support structure removal and model finishing, and pre and post-print consultation for each order.  Our best effort is to remove supports to the extent possible.  In some cases, the support material may need to be removed by the requestor.  Those cases will be discussed as the need arises.</p>
                    <p className="lh-lg">The Confidential Service option is for a print job that contains sensitive data or models. As the printing occurs in an open lab in the Innovation CoLab Studio, we cannot guarantee complete privacy. However, this request notifies our staff to make extra efforts to not place or store your job in a way it can be seen by others.</p>
                    <p className="lh-lg">Premium Material Charge is calculated during estimation or invoicing if the material is not regularly stocked or is a more expensive material than our usual resources. A premium material may even be due to the purge sequence preformed on replacing the specific material on a printer.</p>
                    <p className="lh-lg">The DesignHub Prototyping Service is a pathway for Designhub projects to funnel through the Bluesmith workflow in order to provide prototype support and iterative testing and analysis. Select this option if the job is coming from an existing DesignHub project.</p>
                    <img
                        src={services2}
                        alt='consultation'
                        className='illustrations'
                    />
                    {/* <h2>Methods and Materials</h2>
                    <p className="lh-lg">If you'd like to use a material not on our list or currently out of stock, expect a 2 week or longer lead time in ordering new materials. If the new material is on any of the Stratasys machines or the MJP expect to have a Bluesmith technician to reach out for a consultation as material changes on those printers require reconfiguration of the machine and additional material purge costs may be incurred.</p>
                    <div className="accordion" id="accordionExample">
                        {printers.map((p, index) => accordion(p, index))}
                    </div> */}
                    <h2>Terms of Service</h2>
                    <h3>Services</h3>
                        <p>
                            Our staff will work diligently to complete your print job in a timely manner. Printer and
                            material availability, job complexity, consultations, and clinical priority work all contribute
                            to possible delays in service so we cannot guarantee a delivery time. However we will
                            communicate regularly and offer additional status updates upon request. 
                        </p>
                        <p>
                            3D printing is an experimental technology that often fails. Equipment failures are not
                            unusual. Our staff will make every attempt to print your object successfully. If equipment
                            breaks or the print failed without cause we will attempt to print it again. The likelihood of
                            failure increases significantly with more difficult-to-print models. If the failure is a result of
                            simply submitting too complex of a model, customers will still be charged an attempt fee of
                            half the price of the initial quote. Users can accept the failed job or we can make a second
                            attempt at the print; in this scenario, Bluesmith technicians can make additional attempts
                            at 50% of the original estimate. The user is responsible for the risks associated in the end
                            use application of their 3D printed parts due to the experimental nature of the methods and
                            materials of these technologies.
                        </p>
                        
                    <h3>Pricing</h3>
                        <p>
                            Print charges are estimated based on the material they will use and the time it takes for
                            staff to process them. Actual costs of completed jobs may vary from the issued estimate,
                            and you are responsible for final cost within a reasonable amount increase. The cost of 3D
                            printing varies widely and is based on the technology and amount of material used.
                            Material prices are liable to change frequently and thus our printing costs may also change
                            without notice for new jobs.
                        </p>
                </div>
        </div>
    </div>
}