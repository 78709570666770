import { useContext, useEffect, useState } from "react";
import { Button } from "../../common/Button";
import CostTable from "../../common/CostTable";
import axios from "axios";
import { FlashContext } from "../../flash-context";
import { makeNumberCurrency } from "../../helpers";
import PaymentForm from "../../user/PaymentForm"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'


export default function AdminCostTab(props) {
    const [payForClient, setPayForClient] = useState(false)
    const { flash, setFlash } = useContext(FlashContext);

    const estimate = () => {
        let estimateTitle = <h3>Estimates</h3>;
        if (props.job.estimate) { estimateTitle = <h3>{`Estimate Total: $${makeNumberCurrency(props.job.estimate)}`}</h3> }
        return <div className="tab-content-titles">
            <div>
                {estimateTitle}
                <h5>Auto-approval of estimate is <b>{props.job.auto_approve_value != null ? `enabled under $${props.job.auto_approve_value}` : 'disabled'}</b></h5>
            </div>
            <div>
                {props.job.estimate ? <span className="badge text-bg-success" data-cy="admin-cost-estimate-badge">Sent</span> : <span className="badge text-bg-secondary" data-cy="admin-cost-estimate-badge">Not Sent Yet</span>}
                {props.job.estimate ? <a title="Download Estimate" className="btn" href={`${process.env.REACT_APP_API_HOST}/jobs/${props.job.id}/estimate_pdf`} download><FontAwesomeIcon icon={faFileArrowDown} /></a> : ''}
            </div>
        </div>;
         
    }

    const invoice = () => {
      const machine_ids = props.job.machine_material_combos?.map(j => j.machine_id)
      const material_ids = props.job.machine_material_combos?.map(j => j.material_id)
      const containsWildCard = (machine_ids?.includes(0) || machine_ids?.includes(null)) || (material_ids?.includes(0) || material_ids?.includes(null))
      console.log("array" , props.job.machine_material_combos)
      console.log("machine_ids", machine_ids)
      console.log("material_ids", material_ids)
      console.log("containsWildCard", containsWildCard)


      if(props.job.machine_material_combos?.length < 1 || containsWildCard){
        return <div className="tab-content-titles">
        <div class="alert alert-warning" role="alert">
          A printer and material must be set on the job before sending an invoice.
        </div>
      
        </div>
      }else{
        let invoiceTitle = <h3>Invoice</h3>;
        if (props.job.cost) { invoiceTitle = <h3>{`Invoice Total: $${makeNumberCurrency(props.job.cost)}`}</h3> }
        return <><div className="tab-content-titles">
            {invoiceTitle}
            <div>
                {props.job.cost ? <span className="badge text-bg-success" style={{marginTop: "17px"}}>Sent</span> : 
                <span className="badge text-bg-secondary" style={{marginTop: "17px"}}>Not Sent Yet</span>}
                {props.job.cost ? <a title="Download Invoice" className="btn" href={`${process.env.REACT_APP_API_HOST}/jobs/${props.job.id}/invoice_pdf`} download><FontAwesomeIcon icon={faFileArrowDown} /></a> : ''}
            </div>
        </div>

        {props.job.estimate ? showTable(props.job.invoice_items, 2, "invoice") : ''}
        </>
      }
         
    }

    const showTable = (items, typeId, type) => {
        return <>
            <CostTable job={props.job} items={items} typeId={typeId} updateItem={updateItem} getJob={props.getJob}
                reset={props.reset} setReset={props.setReset} machineMaterialCombos={props.job.machine_material_combos} machines={props.job.machine_details}/>
            <Button className="btn btn-primary btn-margin-fix" dataCy={type === "estimate"? "admin-cost-send-estimate":"admin-cost-send-invoice"} type="button" id="line_items" label="Send" onClick={() => {
                if (window.confirm("Are you sure to send the cost?")) { props.updateJob(`set_${type}`) }
                console.log("what is this? ", `set_${type}`);
            }} />
        </>
    }

    const showPaymentHistory = () => {
        const paymentList = []
        if (props.job.payments && props.job.payments.length > 0) {
            props.job.payments.forEach(payment => {
                paymentList.push(
                    <ul key={payment.uuid} data-cy="payment-history-list">
                        <li>Type: {payment.type === "FundCodePayment"? "FundCode":payment.type}</li>
                        <li>Amount: ${makeNumberCurrency(payment.amount)}</li>
                        <li>Paid on: {payment.pretty_paid_at}</li>
                        <li>Paid By: {payment.paid_by.display_name} | {payment.paid_by.duid}</li>
                    </ul>
                )
            })
            return paymentList
        }
    }

    const updateItem = (item) => {
        if (window.confirm("Are you sure you want to update?")) {
            let method = "PUT";
            let url = `line_items/${item.id}`;
            const form = new FormData();
            if (item.id === "-1") {
                method = "POST";
                url = "line_items/";
                form.append("bs_job_id", props.id);
                form.append("item_type_id", item.itemTypeId);
            }
            form.append("quantity", item.quantity);
            form.append("price", item.price);
            form.append("subtotal", item.subtotal);
            form.append("item_desc_id", item.itemDescId);
            console.log("UPDATE ITEM", item.itemDescMachineId);
            form.append("item_desc_name", item.itemDescName);
            form.append("item_desc_machine_id", item.itemDescMachineId);
            form.append("item_desc_material_id", item.itemDescMaterialId);
            if (item.itemNotes) { form.append("notes", item.itemNotes) }

            const options = {
                method: method,
                url: url,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'enctype': 'multipart/form-data'
                },
                data: form
            };

            axios.request(options).then(function (response) {
                if (response.data.error) {
                    console.log(response.data);
                } else {
                    setFlash({ type: "success", message: "Updated Item!" });
                    props.setReset(true);
                }
            }).catch(function (error) {
                console.error(error);
                setFlash({ type: "danger", message: "Description needed to save cost item!" });
            });
        }
    }

    const paymentHeader = () => {
        let paymentTitle = <h3>Payments</h3>
        return <div className="tab-content-titles">
                {paymentTitle}
                {props.job.payments && props.job.payments.length > 0 ? <span className="badge text-bg-success">Received</span> : <span className="badge text-bg-secondary">Not Received Yet</span>}
        </div>
    }

    const payments = () => {
      if(props.job.machine_material_combos?.length < 1){return}
      return <>
        {paymentHeader()}
        {showPaymentHistory()}
        {showPaymentForm()}
      </>
    }

    const showPaymentForm = () => {
      if (payForClient) {
        return <PaymentForm jobId={props.job.id} />
      }else{
        return <button className="btn btn-info btn-margin-fix" data-cy="admin-pay-for-client" onClick={()=> setPayForClient(true)}>Pay for client</button>

      }
    }



    return <>
        {estimate()}
        {showTable(props.job.estimate_items, 1, "estimate")}
        {invoice()}
        {payments()}

    </>

}