
import React from 'react';
import { useContext, useEffect, useState} from "react";
import axios from "axios";
import { FlashContext } from '../flash-context';
import DataTable from 'react-data-table-component';
import { CSVLink, CSVDownload } from "react-csv";
import BsDataTable from '../common/BsDataTable';
import { Payment } from '../models/payment';



export default function PaymentsHistory(){
  const { flash, setFlash } = useContext(FlashContext);
  const [paymentHistory, setPaymentHistory] = useState([])
  const [filteredHistory, setFilteredHistory] = useState([])


  useEffect(() => {
    getPaymentHistory();
  }, [])

  const getPaymentHistory = () => {
    Payment.all().then((response) => {
      if (response.response) {
          console.log(response);
          setFlash({type: "danger",  message: "Unable retrieve payment history."})
      } else {
        console.log(response);
        setPaymentHistory(response.data)
        setFilteredHistory(response.data)
      }
    });
  }

  const showTable = () => {
    // the key is the display you want on the table and the value is the key for the data returned from the api
    const columnSettings = {
      'Job': 'job_name',
      'Paid By': 'paid_by_display_name',
      'Amount': 'amount',
      'UUID': 'uuid',
      'Type': 'type',
      'Date': 'paid_at'
    }

    return <BsDataTable
      setFilteredData={setFilteredHistory} 
      columnSettings={columnSettings} 
      filteredData={filteredHistory} 
      originalData={paymentHistory}
      csvFilename="bluesmith-payments.csv"
    />
  }

  return <>
  <h1>Payment History</h1>
    {showTable()}
  </>
}