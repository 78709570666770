import { useEffect, useState, useContext } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import '../../assets/css/chat.css'
import { UserContext } from "../../user-context";

const ws = new W3CWebSocket(process.env.REACT_APP_WEBSOCKET_HOST);

export default function ChatView(props) {
    const { user, setUser } = useContext(UserContext);
    const [userMsg, setUserMsg] = useState("")
    const [messages, setMessages] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [textArea, setTextArea] = useState()

    useEffect(() => {
        ws.onopen = () => { console.log('WebSocket Client Connected') }; //open the web socket connection


        if(onAdminSide() && (user.type === "Admin" || user.type === "Employee")){
          waitForSocketConnection(ws, subscribeToInternalCable); //subscribe to the work note chat, but only if an admin and on the admin chat page
        } 
        waitForSocketConnection(ws, subscribeToPublicCable);
        //this appears to get all existing messages
        waitForSocketConnection(ws, wsBroadcast);

        //scroll window to bottom
        const element = document.getElementById("chat-container");
        if (element) { element.scrollTop = element.scrollHeight; }
        const element2 = document.getElementById("notes-container");
        if (element2) { element2.scrollTop = element2.scrollHeight; }
    });


    // sub-component for chat bubble
    function ChatBubble(props) {
      const bubbleColor = props.type === "WorkNote" ? "worknote-bubble" : "public-bubble"
      const bubbleClass = `chat-bubble ${bubbleColor}`
      return  <div className="card text-bg-primary mb-3 admin-chat">
        <div className={bubbleClass}>
          <p className="card-title"><b>{props.user?.display_name || props.user?.displayName}</b> | {props.pretty_created_at}</p>
          <p className="card-text">{props.body}</p>
        </div>
      </div>
    }

    //handle the input for the chat TODO, maybe this needs to be a seperate component?
    function chatInput() {
        // send on enter, shift + enter for newline
        const handleKeyDown = (event) => {
            // if (event.key === 'Enter' && !event.shiftKey) {
            //     sendMsg();
            //     setTextArea("");
            //     event.target.focus();
            //     event.target.setSelectionRange(0, 0);
            // }
        }

        //ask for a confirmation when on the admin page, otherwise fire away!
        const handleButton = (e) => {
          if(e.target.id === "public-msg-btn" && onAdminSide()){
            if(window.confirm("This message will be seen be the clients. Proceed?")){
              sendMsg(e.target.id);
              setTextArea("");
            } 
          }else{
            sendMsg(e.target.id);
            setTextArea("");
          }

        }

        const sendMsg = (msgType) => {
            const msg = JSON.stringify({ body: userMsg })
            //note that all the identifiers here MUST match exactly to the subscription
            let identifier = { channel: msgType === "internal-msg-btn" ? "InternalMessagesChannel" : "PublicMessagesChannel", jobID: props.jobId, includeAdmin: onAdminSide() }
            if (onAdminSide()){identifier.ignoreHistory = true}
            identifier = JSON.stringify(identifier)
            ws.send(JSON.stringify({ "command": "message", "data": msg, "identifier": identifier }))
            setUserMsg("")
        }

        const postInternalBtn = () => {
          if(onAdminSide() && (user.type === "Admin" || user.type === "Employee")){
            return <button id="internal-msg-btn" onClick={handleButton} type="button" className="btn btn-marginless">  <i className="bi bi-send"></i> Post Internal </button>
          }          
        }
    

        return <div>
            <div className="mb-3">
                <label htmlFor="exampleFormControlTextarea1" className="form-label label-body">Enter Message:</label>
                <div className="flexChildText">
                    <textarea
                        onKeyDown={handleKeyDown}
                        onChange={(e) => { setUserMsg(e.target.value); setTextArea(e.target.value) }}
                        className="form-control bs-chat-area"
                        rows="3"
                        value={textArea}>
                    </textarea>
                </div>
                <br/>
                <div>
                    <button id="public-msg-btn" onClick={handleButton} type="button" className="btn btn-marginless">  <i className="bi bi-send"></i> Send Message </button>
                    {postInternalBtn()}
                </div>
            </div>

        </div>
    }



    //populate the inital window and any new message postings
    function handleBroadcast(inboundMsg) {
        const wsMsgType = JSON.parse(inboundMsg?.data)?.message?.type
        console.log(`wsMsgType| ${wsMsgType}`)
        if (wsMsgType === "all") {
            if (!loaded) { //check that messages are not already reloaded so we dont accidently add a duplicate history to all ws connections
                console.log("GETTING MESSAGE HISTORY")
                msgHistory(JSON.parse(inboundMsg?.data).message.messages)
            }
        } else if (wsMsgType === "update") {
            const newMsg = JSON.parse(inboundMsg?.data).message.message
            setMessages(current => [...current, <ChatBubble key={newMsg.id} user={newMsg.user} body={newMsg.body} pretty_created_at={newMsg.pretty_created_at} type={newMsg.message_type} />])
        }
    }


    const msgHistory = (existingMsgs) => {
        existingMsgs.forEach(m => setMessages(current => [...current, <ChatBubble user={m.user} key={m.id} body={m.body} pretty_created_at={m.pretty_created_at} type={m.message_type} />]));
        setLoaded(true);
    }




    //subcribe the ws connection to a channel form the ActionCable Api
    function subscribeToInternalCable() {
      let identifier = { channel: "InternalMessagesChannel", jobID: props.jobId, includeAdmin: onAdminSide()}
      if (onAdminSide()){identifier.ignoreHistory = true} //noted this does NOTHING for the internal cable, but it must match the send so that the socket works
      identifier = JSON.stringify(identifier)
      ws.send(JSON.stringify({ "command": "subscribe", "identifier": identifier }));
    }

    function subscribeToPublicCable() {
      let identifier = { channel: "PublicMessagesChannel", jobID: props.jobId, includeAdmin: onAdminSide() }
      // let identifier = JSON.stringify({ channel: "PublicMessagesChannel", jobID: props.jobId, includeAdmin: onAdminSide() })
      if (onAdminSide()){identifier.ignoreHistory = true}
      identifier = JSON.stringify(identifier)
      console.log("identifier")
      console.log(identifier)
      ws.send(JSON.stringify({ "command": "subscribe", "identifier": identifier }));
    }

    


    // Make the function wait until the connection is made...
    function waitForSocketConnection(socket, callback) {
        setTimeout(
            function () {
                if (socket.readyState === 1) {
                    if (callback != null) {
                        callback();
                    }
                } else {
                    waitForSocketConnection(socket, callback);
                }

            }, 50); // wait 50 milisecond for the connection...
    }

    //handle broadcasts from the websocket
    function wsBroadcast() {
        ws.onmessage = (message) => { handleBroadcast(message); }
    }

    const chatNoMessage = <>
        <h2>Questions? Message Us</h2>
        <div id="chat-container">
            {messages}
            <div>
                {chatInput()}
            </div>
        </div>
    </>

    const chatHasMessages = <>
        <h2>Message Us </h2>
        <div id="chat-container">
            {messages}
        </div>
        <div>
            {chatInput()}
        </div>
    </>

    const adminChat = <>
        <h2>Chat History</h2>
        <div id="chat-container">
            {messages}
        </div>
        <div>
            {chatInput()}
        </div>
    </>

    if (onAdminSide()){
        return adminChat;
        //chat view on admin side
    }
    else if (messages.length == 0){
        return chatNoMessage;
        // chat view when there ARE NO messages
    }
    else {
        return chatHasMessages;
        // chat view when there ARE messages
    }


    // checking the url to know if we are on the admin side
    function onAdminSide() {
        var jobUrl = window.location.href;
        var result = jobUrl.match(/admin/g);
        return result;
    } 

}