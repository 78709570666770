
import { ApiCore } from '../service/core';
import {apiProvider} from '../service/provider'

export class User extends ApiCore {
	constructor(attrs) {
		const options = { basePath: 'users' };
		super(options);
		this.attrs = attrs;
	}
	// static get functions must not be arrow functions
	static get basePath() {
		return 'users';
	}

  static admins_and_employees() {
    return apiProvider.get("/users?admins_and_employees=1");
  }

  static grouperSync = () =>{
		return apiProvider.get("/users/grouper_sync/sync");
	}
}