import { Link } from "react-router-dom";

export default function Table(props) {

    return (
        <div>
            {/* className="table-responsive-stack" */}
            <table className={props.noHover? "table":"table table-hover"} data-cy={props.dataCy}>
                <thead>
                    <tr>
                        {props.colName.map((c, index) => {
                            /* the following code will put a red star after any column named description" */
                            if (c == "Description"){
                                c = <div>Description <span className="red-star">*</span></div>;
                            }
                            return (<th scope="col" key={'col' + index}>{c}</th>)
                        })}
                    </tr>
                </thead>
                <tbody>
                    {props.data.map((d, index) => {
                        if (props.names) {
                            return <tr key={index}>
                                {props.names.map((n, index) => {
                                    return <td key={'value' + index}>{d[n]}</td>
                                })}
                                <td>{props.button ? <Link className="btn btn-smaller" label="View" to={`${d.id}`}>View</Link> : ''}</td>
                            </tr>
                        } else {
                            return (<tr key={index}>
                                {Object.values(d).map((value, i) => {
                                    return <td key={'value' + i}>{value}</td>
                                })}
                                <td>{props.button ? <Link className="btn btn-smaller" label="View" to={`${d.id}`}>View</Link> : ''}</td>
                            </tr>)
                        }
                    })}
                </tbody>
            </table>
        </div>
    )
}