// https://react-select.com/home#getting-started
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import axios from "axios";

const loadOptions = async (input) => {
    let self = this;
    let options = [];
    let cancelled = false;
    if (input.length >= 4 && !cancelled) {
        [options, cancelled] = await axios.get(`users/ldap_search/${input}`).then((response) => {
            if (response.response) {
                self.props.handleError(response);
            } else {
                let result = response.data;
                result.map((elem) => {
                    options.push({
                        value: elem.dudukeid,
                        label: `${elem.displayname}(${elem.uid}) - ${elem.edupersonprimaryaffiliation}`
                    });
                });
                return [options, true];
            }
        });
    }
    return options;
};

export default function UserSearch(props) {
    const [invite, setInvite] = useState([]);

    useEffect(() => {
        setInvite(props.invite)
    }, [props.invite])
    return <div data-cy="create-job-add-collaborator">
        <AsyncSelect
            loadOptions={loadOptions}
            cacheOptions
            onChange={props.handleChange}
            value={invite}
            placeholder="Search by duid, netid or name"
            isMulti="true"
        />
    </div>
}
