import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import MaterialForm from "./MaterialForm";
import { Material } from "../../models/material";

export default function ViewMaterial() {
    const { id } = useParams();
    const [material, setMaterial] = useState([]);
    const [defaultAttributes, setDefaultAttributes] = useState([]);

    const getMaterial = () => {
        Material.get(id).then((response) => {
        if (response.response) {
            console.log(response);
        } else {
          console.log(response);
          let data = response.data;
          let attributes = [];
          let ids = [];
          data.material_attributes.map(m => {
            attributes.push({ value: m.id, label: m.name });
            ids.push(m.id);
          })
          data.material_attributes = ids;
          setDefaultAttributes(attributes);
          setMaterial(response.data);
        }
      });
    }

    useEffect(() => {
        getMaterial();
    }, [])

    return <>
        <h1>View Material</h1>
        <MaterialForm material={material} method="PUT" url={`materials/${id}`}
            edit={true} defaultAttributes={defaultAttributes}/>
    </>
}