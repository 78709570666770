
function makeid(length) {
	var result = '';
	var characters =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export const getData = async (apiElement) => {
	return await apiElement.all().then((response) => {
		return response;
	});
};

export const makeNumberCurrency = (number) => {
	return parseFloat(number).toFixed(2);
}

export const handleInputChange = (event, setter) => {
	if (event.target.type === 'file') {
		setter(event.target.files)
	} else if (event.target.type === "checkbox") {
		setter(event.target.checked)
	} else {
		//console.log(event.target.value);
		setter(event.target.value)
	}
}


export const loginWithDuke = () => {
	localStorage.setItem('redirect_url', window.location.href)
	let relay = makeid(20);
	let acs = process.env.REACT_APP_SAML_ACS;
	let url =
		'https://shib.oit.duke.edu/idp/profile/SAML2/Unsolicited/SSO?providerId=https://bluesmithpro.duke.edu&shire=' +
		acs +
		'&target=' +
		relay;
	window.location.href = url;
	return null;
};

export const logoutWithDuke = () => {
	localStorage.clear();
	window.location.href = `${process.env.REACT_APP_API_HOST}/saml/logout`;
};


export const formatJobState = (job) => {
	let status = job.state.replaceAll("_", " ");
	if (job.picked_up && !job.paid) {
		status = `${job.state.replaceAll("_", " ")} and waiting for payment`
	}
	return status
}