import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Editor } from '@tinymce/tinymce-react';
import { FlashContext } from "../../flash-context";

export default function ContentForm(props) {
    const [blurb, setBlurb] = useState(props.blurb);
    const { flash, setFlash } = useContext(FlashContext);

    useEffect(() => {
        setBlurb(props.blurb);
    }, [props.blurb])

    const handleChange = (e) => {
        let name = e.target.id;
        let value = (name === "active") ? e.target.checked : e.target.value;
        let newBlurb = blurb;
        newBlurb[name] = value;
        setBlurb(newBlurb)
    }

    const blurbName = () => {
        return <div className="mb-3">
            <label htmlFor="name" className="form-label">Name</label><span className="red-star">*</span>
            <select className="form-select" aria-label="Default select example" id="name" onChange={e => handleChange(e)} defaultValue={blurb.name}>
                <option hidden>Select a name</option>
                <option value="announcements" key="announcements">Announcements</option>
            </select>
        </div>
    }

    const blurbContent = () => {
        return <div className="mb-3">
            <label htmlFor="content" className="form-label">Content</label><span className="red-star"></span>
            <textarea onChange={e => handleChange(e)} type="text" className="form-control" id="content" aria-describedby="content" placeholder="A short content to identify this blurb." defaultValue={blurb.content}></textarea>
        </div>
    }

    // const handleWysiwygChange = (content) => {
    // 	let newBlurb = blurb;
    //     newBlurb.content = content;
    //     setBlurb(newBlurb)
    // };

    // const blurbContent = () => {
    //     return <div className="mb-3">
    //         <label htmlFor="content" className="form-label">Content</label><span className="red-star">*</span>
    //         <Editor
    //             init={{ height: 300, menubar: false }}
    //             plugins='link lists preview'
    //             toolbar='undo redo | bold italic underline | bullist | link | indent outdent | aligncenter alignjustify alignleft alignright | preview'
    //             initialValue={blurb.content}
    //             onEditorChange={handleWysiwygChange}
    //         />
    //     </div>
    // }

    const blurbDisplay = () => {
        return <div className="form-check">
            <label htmlFor="active" className="form-check-label">Display </label>
            <input onClick={e => handleChange(e)} className="form-check-input" type="checkbox" id="active" aria-describedby="active" placeholder="A display for this content." defaultChecked={blurb.active} />
        </div>
    }

    const submitForm = (e) => {
        e.preventDefault()
        const form = new FormData();

        form.append("name", blurb.name);
        form.append("content", blurb.content);
        form.append("active", blurb.active);
        console.log(blurb);

        const options = {
            method: props.method,
            url: props.url,
            headers: {
                'Content-Type': 'multipart/form-data',
                'enctype': 'multipart/form-data'
            },
            data: form
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            if (response.data.error) {
                setFlash({ type: "danger", message: response.data.messages });
            } else {
                window.location.pathname = `./admin/content_editor`;
            }
        }).catch(function (error) {
            console.error(error);
        });
    }



    const deleteForm = (e) => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to delete this Content?")) {

            const options = {
                method: "DELETE",
                url: props.url,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'enctype': 'multipart/form-data'
                }
            };

            axios.request(options).then(function (response) {
                console.log(response.data);
                if (response.data.error) {
                    setFlash({ type: "danger", message: response.data.messages });
                } else {
                    window.location.pathname = `./admin/content_editor`;
                }
            }).catch(function (error) {
                console.error(error);
            });
        }
    }
    return <>
        <form>
            {blurbName()}
            {blurbContent()}
            {blurbDisplay()}
            <button data-cy="create-update-button" onClick={submitForm} type="submit" className="btn btn-primary">{props.edit ? "Update" : "Create"}</button>
            <span>{' '}</span>
            {props.edit ? <button onClick={deleteForm} data-cy="delete-button" type="button" className="btn btn-danger">Delete</button> : ''}
            {!props.edit ? <button onClick={() => window.location.pathname = "./admin/printers"} type="button" className="btn btn-light">Cancel</button> : ''}
        </form>
    </>
}