import { ApiCore } from '../service/core';
import { apiProvider } from '../service/provider';

export class Blurb extends ApiCore {
	constructor(attrs) {
		const options = { basePath: 'blurbs' };
		super(options);
		this.attrs = attrs;
	}

	// static get functions must not be arrow functions
	static get basePath() {
		return 'blurbs';
	}

	getName = (param) => {
		return apiProvider.get(`${this.basePath}?name=${param}`);
	};

}
