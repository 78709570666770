export default function Pagination(props) {

    const getPages = () => {
        let pages = [];
        for (let i = 0; i < props.count; i += props.limit) {
            pages.push(<li className={(props.offset / props.limit) == (i / props.limit) ? "page-item active" : "page-item"} 
            key={i} onClick={() => props.setOffset(i)}><span className="page-link page-number" data-cy="pagination-page-number" key={i}>{(i / props.limit) + 1}</span></li>)
        }
        return pages;
    }

    return <nav aria-label="Page navigation example" data-cy="pagination">
        <ul className="pagination justify-content-center">
            <li className={props.offset - props.limit < 0 ? "page-item disabled" : "page-item"} 
            onClick={() => { if (props.offset - props.limit >= 0) { props.setOffset(o => o - props.limit) } }}>
                <span className="page-link">Previous</span>
            </li>
            {getPages()}
            <li className={props.offset + props.limit > props.count ? "page-item disabled" : "page-item"} 
            onClick={() => { if (props.offset + props.limit <= props.count) { props.setOffset(o => o + props.limit) } }}>
                <span className="page-link">Next</span>
            </li>
        </ul>
    </nav>
}