//interceptors for global response code handle
//setflash is a function passed in that sets the global flash context
import axios from 'axios';

export const axiosGlobalIntercept = (setFlash) => {
  axios.interceptors.response.use(
    (response) => {
      //anything global we want for all successfull/200 range codes here
      return response;
    },
    (err) => {
      if (err.response.status === 401) {
        setFlash({type: "warning", code: 401, message: "Must be logged in to take this action."})
      } else if (err.response.status === 403) {
        setFlash({type: "danger", code: 401, message: "You don't have permissions to take this action."})
      } else if (err.response.status === 500){
        setFlash({type: "danger", code: 500, message: "An unknown error has occured."})
      } else {
        console.log(err.response.status)
      }
      return Promise.reject(err);
    }
  );
}