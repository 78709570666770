import { ApiCore } from '../service/core';
import { apiProvider } from '../service/provider';

export class Job extends ApiCore {
	constructor(attrs) {
		const options = { basePath: 'jobs' };
		super(options);
		this.attrs = attrs;
	}

	// static get functions must not be arrow functions
	static get basePath() {
		return 'jobs';
	}

	static setAutoApprove = (id, value) => {
		return apiProvider.put(
			`jobs/${id}`,
			{auto_approve_value: value}
		)
	}	
	
	static updateMachineMaterialCombos = (params, jobId) => {
		return apiProvider.post(
			`jobs/${jobId}/update_machine_material_combos`,
			params
		)
	}

	filter = (type) => {
        let path = "";
        console.log(path)
        if (type==="active"){
            path="archived=false&in_progress=true";
        }
        else if (type==="pending_staff") {
            path="archived=false&pending_staff=1";
        }
        else if (type==="pending_client") {
          path="archived=false&pending_client=1";
      }
        else if (type==="complete"){
            path="archived=false&complete=true";
        }
		return apiProvider.get(`${this.options.basePath}?${path}`);
	};

	setAdmins = (param) => {
		return apiProvider.put(
			`${this.options.basePath}/${this.attrs.id}/assign_job`,
			{ admin_ids: param }
		);
	};

	toggle_mail = () =>{
		return apiProvider.get(`${this.options.basePath}/${this.attrs.id}/toggle_mail`);
	}
	
	updatePrimaryClient = (param) => {
		return apiProvider.put(
			`${this.options.basePath}/${this.attrs.id}`,
			{ primary_client_id: param }
		);
	};
}
