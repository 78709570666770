import { ApiCore } from '../service/core';
import { apiProvider } from '../service/provider';

export class Material extends ApiCore {
	constructor(attrs) {
		const options = { basePath: 'materials' };
		super(options);
		this.attrs = attrs;
	}

	// static get functions must not be arrow functions
	static get basePath() {
		return 'materials';
	}

	getAttributes = (param) =>{
		return apiProvider.get(`${this.options.basePath}?attribute_id=${param}`);
	}

	static toggle_display = (id) => {
		return apiProvider.put(
			`materials/${id}/toggle_display`
		)
	}
}