import { useEffect, useState, useContext } from "react";
import axios from "axios";
import Select from 'react-select';
// import tinymce from 'tinymce/tinymce.min.js';

// TinyMCE wants to be in global scope, even if loaded from npm module
// window.tinymce = tinymce;
import { Editor } from '@tinymce/tinymce-react';
// import tinymce from 'tinymce/tinymce';

import { FlashContext } from "../../flash-context";

export default function PrinterForm(props) {
    const [printer, setPrinter] = useState(props.printer);
    const [options, setOptions] = useState(false);
    const [defaultSelect, setDefaultSelect] = useState(props.defaultSelect);
    const { flash, setFlash } = useContext(FlashContext);

    const getMaterials = () => {
        axios.get('/materials')
            .then(res => {
                let optionList = [];
                res.data.map(d => {
                    optionList.push({ value: d.id, label: d.name })
                })
                setOptions(optionList);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        getMaterials();
    }, [])

    useEffect(() => {
        setPrinter(props.printer);
    }, [props.printer])

    useEffect(() => {
        setDefaultSelect(props.defaultSelect);
    }, [props.defaultSelect])

    const handleChange = (e) => {
        let name;
        let value;
        if (e.target) {
            name = e.target.id;
            value = e.target.value;
        } else {
            name = "materials";
            value = [];
            let newSelect = [];
            e.map(data => {
                value.push(data.value);
                newSelect.push({ label: data.label, value: data.value });
            })
            setDefaultSelect(newSelect)
        }
        let newPrinter = {...printer};
        newPrinter[name] = value;
        setPrinter(newPrinter)
    }

    const printerName = () => {
        return <div className="mb-3">
            <label htmlFor="name" className="form-label">Name</label><span className="red-star">*</span>
            <input onChange={e => handleChange(e)} type="text" className="form-control" id="name" aria-describedby="name" placeholder="A short descriptive name to identify this machine." required defaultValue={printer.name} />
        </div>
    }

    const printerHourlyCost = () => {
        return <div className="mb-3">
            <label htmlFor="hourly_cost" className="form-label">Hourly Cost</label>
            <input onChange={(e) => handleChange(e)} type="number" className="form-control" id="hourly_cost" aria-describedly="hourly_cost" defaultValue={printer.hourly_cost}/>
        </div>
    }

    const printerBaseCharge = () => {
        return <div className="mb-3">
            <label htmlFor="base_charge" className="form-label">Base Charge</label>
            <input onChange={(e) => handleChange(e)} type="number" className="form-control" id="base_charge" aria-describedly="base_charge" defaultValue={printer.base_charge}/>
        </div>
    }

    // const printerSummary = () => {
    //     return <div className="mb-3">
    //         <label htmlFor="summary" className="form-label">Summary</label><span className="red-star">*</span>
    //         <input onChange={e => handleChange(e)} type="text" className="form-control" id="summary" aria-describedby="summary" placeholder="A short summary to identify this machine." required defaultValue={printer.summary} />
    //     </div>
    // }
    
    const handleWysiwygChange = (content) => {
		let newPrinter = printer;
        printer.description = content;
        setPrinter(newPrinter)
	};

    const printerDescription = () => {
        return <div className="mb-3">
            <label htmlFor="description" className="form-label">Description</label><span className="red-star">*</span>
            <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                init={{ height: 300, menubar: false }}
                plugins='link lists preview'
                toolbar='undo redo | bold italic underline | bullist | link | indent outdent | aligncenter alignjustify alignleft alignright | preview'
                initialValue={printer.description}
                onEditorChange={handleWysiwygChange}
            />
            {/* <textarea onChange={e => handleChange(e)} type="text" className="form-control" id="description" rows="10" aria-describedby="description" placeholder="A short description to identify this printer." required defaultValue={printer.description}></textarea> */}
        </div>
    }

    const printerType = () => {
        return <div className="mb-3">
            <label htmlFor="type" className="form-label">Type</label>
            <input onChange={e => handleChange(e)} type="text" className="form-control" id="type" aria-describedby="type" placeholder="A type for this printer." defaultValue={printer.type ? printer.type : "Machine"} disabled />
        </div>
    }

    const printerMaterials = () => {
        return <div className="mb-3">
            <label htmlFor="materials" className="form-label">Materials</label>
            <Select isMulti options={options} id="materials" onChange={e => handleChange(e)} value={defaultSelect} />
        </div>
    }

    const submitForm = (e) => {
        e.preventDefault()
        const form = new FormData();

        form.append("name", printer.name);
        // form.append("summary", printer.summary);
        form.append("hourly_cost", printer.hourly_cost);
        form.append("base_charge", printer.base_charge);
        form.append("description", printer.description);
        // form.append("type", printer.type);
        //console.log(printer);
        form.append("materials", printer.materials);

        const options = {
            method: props.method,
            url: props.url,
            headers: {
                'Content-Type': 'multipart/form-data',
                'enctype': 'multipart/form-data'
            },
            data: form
        };

        axios.request(options).then(function (response) {
            if (response.data.error) {
                setFlash({ type: "danger", message: response.data.messages });
            } else {
                window.location.pathname = `./admin/printers`;
            }
        }).catch(function (error) {
            console.error(error);
        });
    }



    const deleteForm = (e) => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to delete this Machine?")) {

            const options = {
                method: "DELETE",
                url: props.url,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'enctype': 'multipart/form-data'
                }
            };

            axios.request(options).then(function (response) {
                if (response.data.error) {
                    setFlash({ type: "danger", message: response.data.messages });
                } else {
                    window.location.pathname = `./admin/printers`;
                }
            }).catch(function (error) {
                console.error(error);
            });
        }
    }
    return <>
        <form>
            {printerName()}
            {printerBaseCharge()}
            {printerHourlyCost()}
            {printerMaterials()}
            {printerType()}
            {/* {printerSummary()} */}
            {printerDescription()}
            <button onClick={submitForm} type="submit" data-cy="create-button" className="btn btn-primary">{props.edit ? "Update" : "Create"}</button>
            <span>{' '}</span>
            {props.edit ? <button onClick={deleteForm} type="button" className="btn btn-danger">Delete</button> : ''}
            {!props.edit ? <button onClick={() => window.location.pathname = "./admin/printers"} type="button" className="btn btn-light">Cancel</button> : ''}
        </form>
    </>
}