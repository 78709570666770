import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../common/Button";
import { UserContext } from "../user-context";
import { loginWithDuke } from '../helpers';
import home1 from '../assets/illustrations/bluesmith_home_01.svg';
import home2 from '../assets/illustrations/bluesmith_home_02.svg';
import home3 from '../assets/illustrations/bluesmith_home_03.svg';
import home4 from '../assets/illustrations/bluesmith_home_04.svg';
import { FlashContext } from "../flash-context";



export default function HomePage() {
    const { user, setUser } = useContext(UserContext);
    const { flash, setFlash } = useContext(FlashContext);

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/createJob`;
        navigate(path);
    }

    const buttons = () => {
        let submitButton = user ?
            <Button className="btn btn-primary submit-job" dataCy="submit-a-job" label="Submit a Job" onClick={routeChange} /> :
            <Button className="btn btn-primary submit-job" dataCy="login" onClick={loginWithDuke} label="Login to Submit A Job" />
        const url = "https://outlook.office365.com/owa/calendar/Bluesmith3DPrintingConsultation@ProdDuke.onmicrosoft.com/bookings/";
        let consultantButton = <a className="btn btn-primary" data-cy="consultation" href={url} target="_blank" rel="noreferrer">Schedule a Consultation</a>
        let emailUs = <p>Or email us at <a href="mailto:bluesmith@duke.edu" data-cy="email-bluesmith">bluesmith@duke.edu</a></p>
        let consultationPanel = <div className="consultation">
            {consultantButton}
            {emailUs}
        </div>
        return <div className="panel">
            {submitButton}
            {consultationPanel}
        </div>
    }

    return <div className="container wrapper">
        <div>
            <header className='home-header'>
                <div className='image-overlay'></div>
                <div className='header-wrapper'>
                    <h1>Bluesmith 3D Printing Service</h1>
                </div>
            </header>
            <div className='main'>
                {buttons()}
                {/* <div className="col-md-9 offset-md-1"> */}
                <div>

                    <p className="lh-lg">Bluesmith is Duke University's professional and experimental 3D printing service. The printer suite and job pickup table is located on West Campus in the CoLab TEC building. This lab specializes printing unique models used in one of a kind research endeavours, mimic human anatomy and aid medical evaluations, and much more!</p>
                    <p className="lh-lg"><strong><i>This service is separate from the free, self-service printing available at the CoLab studio spaces on West, East, and Ruby.</i></strong> Self-service printing is available on our Ultimaker FDM printers in the CoLab Makerspace using a rainbow of PLA material. This option is accessed through <a href="https://duke.3dprinteros.com/" data-cy="3d-printer-os">duke.3dprinteros.com</a> with more information located at the Innovation CoLab Studio page <a href="https://colab.duke.edu/studio#equipment" data-cy="colab-studio-page">here</a>. Self-service printing is first-come-first-served and without charge.</p>
                    <p className="lh-lg">Our team supports a variety of research and medical className systems to provide an amazing array of capabilities for the campus and health system communities. <i>The typical turnaround for a job completion with printing and post processing services is no sooner than 7 days after approval.</i></p>

                    <h2>How it Works</h2>
                    <p className="lh-lg">3D prints will be created for you based on your submissions.  Each 3D printed part is of a custom nature so consultations are often part of the printing process. </p>
                    <ul>
                        <li>The requestor submits their 3D printed files on this webpage - bluesmith.oit.duke.edu - or emails the bluesmith@duke.edu address to coordinate a project consultation with staff. </li>
                        <li>These prints will be configured and run by the expert staff at the print studio. </li>
                        <li>Models are printed on dedicated printers using a variety of materials. Materials may be specified by the requestor or chosen by the lab consultant based on the needs of the application.</li>
                        <li>Bluesmith is a cost-recovery service.  Charged fees are used to recover expenses related to printing materials and other lab consumables.  All money recovered from this service will be used to improve lab operation. </li>
                    </ul>
                    <img
                        src={home2}
                        alt='professional team'
                        className='illustrations'
                    />
                    <p className="lh-lg">All you need to get started is an .STL file of your model. We will get back to you with an estimate in one to three business days. Once the estimate is approved, staff will begin printing. Printing and post-processing typically takes no less than 7 days. Payment is accepted via fund-code or credit-card.</p>

                    <h2>Process Workflow</h2>
                    <img
                        src={home3}
                        alt='process'
                        className='illustrations'
                    />
                    <ol>
                        <li>Name and describe your print job request.</li>
                        <li>Upload the files you want to be printed.</li>
                        <li>Select your desired print options or allow our printing consultants to select the best material and processes for you.</li>
                        <li>Add a project collaborator if desired.</li>
                        <li>Our staff will assess your submission and issue you an estimate, typically this takes two to three business days.</li>
                        <li>Our staff may also reach out to you via our chat feature on the website to request more information or a meeting about your application so that the best methods for production are chosen or to provide design-for-print feedback.</li>
                        <li>Once an estimate is given, review and approve for work to begin on the print request.</li>
                        <li>Once the job is approved our staff will begin the printing. Depending on the printer chosen, there may be an additional post-processing step to refine the part before completion.</li>
                        <li>After printing is completed, pay for your job before pickup.</li>
                        <li>Once paid, come to the print studio pick-up table located at the CoLab TEC building on West Campus to pick up your job!</li>
                    </ol>
                    <img
                        src={home4}
                        alt='successful project'
                        className='illustrations'
                    />
                </div>
            </div>
        </div>
    </div>
}