import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Tabs from "../common/Tabs";
import axios from "axios";
import { FlashContext } from '../flash-context';
import PaymentForm from "./PaymentForm";
import Table from "../common/Table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import ManageClients from "../common/ManageClients";
import { Button } from "../common/Button";
import { UserContext } from "../user-context";
import { formatJobState, makeNumberCurrency, handleInputChange } from "../helpers";
import ChatView from "../common/chat/ChatView";
import { loginWithDuke } from "../helpers";
import OldMachineMaterialTable from "../common/OldMachineMaterialTable";
import MachineMaterialTable from "../common/MachineMaterialTable";
import { Job } from "../models/job"

export default function ViewJob() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState({});
  const { flash, setFlash } = useContext(FlashContext);
  const [filenames, setFilenames] = useState([]);
  const LineItemTitle = ["Description", "Notes", "Quantity", "Price", "Subtotal"];
  const [estimateItems, setEstimateItems] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const { user, setUser } = useContext(UserContext);
  const [costTabState, setCostTabState] =useState("Costs")
  const [autoApproveSelected, setAutoApproveSelected] = useState(false);
  const [autoApproveValue, setAutoApproveValue] = useState(0);

  useEffect(() => {
    if (!user && !localStorage.getItem('isLoggedIn')) {
      loginWithDuke();
    } else {
      getJob();
    }
  }, [user])

  const getJob = () => {
    axios.get(`jobs/${id}`)
      .then(res => {
        if(res.data.data.archived){
          setFlash({ type: "warning", message: `This job has been archived. Please contact the Bluesmith Team if you need data from this job. Job Ref: ${id}` })
          return;
        }
        
        setJob(res.data.data);
        setAutoApproveSelected(res.data.data.auto_approve_value != null);
        setAutoApproveValue(res.data.data.auto_approve_value);
        console.log("res.data.data: ",res.data.data);
        if (res.data.data.filenames) {
          let files = [];
          for (let f of res.data.data.filenames) {
            files.push({ [f.id]: <a href={`${process.env.REACT_APP_API_HOST}/jobs/${id}/download?fileid=${f.id}&filename=${encodeURIComponent(f.filename)}`} download>{f.filename}</a>, "quantity": <p>{f.quantity}</p> })
          }
          setFilenames(files)
        }
        if (res.data.data.estimate_items) {
          let estimateItemList = [];
          for (let l of res.data.data.estimate_items) {
            estimateItemList.push({
              "description": <span>{l.item_desc || "None"}</span>,
              "notes": <span>{l.notes || ''}</span>,
              "quantity": <span>{l.quantity || 0}</span>,
              "price": <span>{makeNumberCurrency(l.price) || 0}</span>,
              "subtotal": <span>{makeNumberCurrency(l.subtotal) || 0}</span>
            })
          }
          setEstimateItems(estimateItemList);
        }
        if (res.data.data.invoice_items) {
          let invoiceItemsList = [];
          for (let l of res.data.data.invoice_items) {
            invoiceItemsList.push({
              "description": <span>{l.item_desc || "None"}</span>,
              "notes": <span>{l.notes || ''}</span>,
              "quantity": <span>{l.quantity || 0}</span>,
              "price": <span>{makeNumberCurrency(l.price) || 0}</span>,
              "subtotal": <span>{makeNumberCurrency(l.subtotal) || 0}</span>
            })
          }
          setInvoiceItems(invoiceItemsList);
        }
        calculateCostTab(res.data.data.state)
      })
      .catch(err => {
        setFlash({ type: "warning", message: `This job does not exist. Please contact the Bluesmith Team if you need help. Job Ref: ${id}` })
      })
  }

  const collaborators = () => {
    return <>
    <h3>Collaborators</h3>
      <ManageClients dataCy="table-collaborators" job={job} getJob={getJob} />
    </>
  }

  const handlePickUp = () => {
    if (window.confirm("Did you pick up the print?")) {

      const form = new FormData();
      form.append("picked_up", true);
      form.append("picked_up_user", user.displayName);
      const options = {
        method: 'PUT',
        url: `jobs/${id}/client_update`,
        headers: {
          'Content-Type': 'multipart/form-data',
          'enctype': 'multipart/form-data'
        },
        data: form
      };

      axios.request(options).then(function (response) {
        if (response.data.error) {
          console.log("ERROR", response.data.messages)
        } else {
          getJob();
          setFlash({ type: "success", message: "You have Picked Up the print." })
        }
      }).catch(function (error) {
        console.error(error);
      });
    }
  }

  const jobDeadline = () => {
    let min;
    const date = new Date()
    if (user.type === "HighRoller"){
      date.setDate(date.getDate() + 1)
    } else if (user.type === "BsClient"){
      date.setDate(date.getDate() + 7)
    }
    min = date.toISOString().split("T")[0]
    return <p>
      <strong>Preferred Deadline: </strong>
      <input onChange={e => handleDeadlineUpdate(e)} type="date" min={min} className="form-control" data-cy="job-deadline" id="JobDeadline" defaultValue={job.deadline} />
    </p>
  }

  const handleMachineMaterialUpdate = (selectedPrinter, selectedMaterials) => {
    const selectedMaterialIds = selectedMaterials.map((m) => m.value);
    let params = {'machine_id': selectedPrinter, 'material_ids': selectedMaterialIds};
    Job.updateMachineMaterialCombos(params, job.id);
  }

  const machineMaterialTable = () => {
      let uniqueMachineIds = [];
      let materialIds = [];
      job.machine_material_combos.forEach((mmc) => {
          materialIds.push({value: mmc.material_id, label: mmc.material_name});
          if (!uniqueMachineIds.includes(mmc.machine_id)) {
              uniqueMachineIds.push(mmc.machine_id);
          }
      })
      if (uniqueMachineIds.length <= 1) {
          return <MachineMaterialTable
              selectedPrinter={uniqueMachineIds[0] || 0}
              selectedMaterials={materialIds}
              handleMachineMaterialUpdate={handleMachineMaterialUpdate}
          />
      } else {
          return <OldMachineMaterialTable job={job} getJob={getJob} />
      }
  }

  const detail = () => {
    let pickup;
    if (!job.picked_up && job.cost) {
      pickup = <Button className="btn btn-primary" label="Picked Up" dataCy="job-pickedup" onClick={handlePickUp} />
    }
    return (
      <>
        <h3>Detail</h3>
        <p data-cy="job-description"><strong>Description: </strong>{job.description}</p>
        <p data-cy="job-application"><strong>Application: </strong>{job.application}</p>
        <p data-cy="job-start-date"><strong>Start Date: </strong>{job.pretty_created_at}</p>
        {jobDeadline()}
        {machineMaterialTable()}
        <p data-cy="job-consultation"><strong>Consultation: </strong>{job.wants_consultation ? "Yes" : "No"}</p>
        {job.is_clinical_service ? <p data-cy="job-clinical"><strong>Clinical Service: </strong>Yes</p> : ''}
        {job.is_confidential ? <p data-cy="job-confidential"><strong>Confidential Service: </strong>Yes</p> : ''}
        {job.is_design_hub ? <p data-cy="job-design-hub"><strong>DesignHub Service: </strong>Yes</p> : ''}
        {job.is_shareable ? <p><strong>Project shareable in our promotional materials: </strong>Yes</p> : ''}
        {job.course_number === 'false' ? '' : <p><strong>Course Number: </strong>{job.course_number}</p> }
        {pickup}
      </>
    )
  }

  const files = () => {
    return <>
      <h3>Files</h3>
      <Table dataCy="table-filenames" colName={["File Name", "Copies"]} data={filenames} />
    </>
  }

  const handleDeadlineUpdate = (e) => {
    const form = new FormData();
    form.append("deadline", e.target.value);
    const options = {
      method: 'PUT',
      url: `jobs/${id}/client_update`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'enctype': 'multipart/form-data'
      },
      data: form
    };

    axios.request(options).then(function (response) {
      if (response.data.error) {
        console.log(response.data.messages)
      } else {
        setFlash({ type: "success", message: "You have updated the deadline." })
      }
    }).catch(function (error) {
      console.error(error);
    });
  }

  const sendEstimateApproval = () => {
    const form = new FormData();
    form.append("estimate_approved", true);
    const options = {
      method: 'PUT',
      url: `jobs/${id}/client_update`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'enctype': 'multipart/form-data'
      },
      data: form
    };

    axios.request(options).then(function (response) {
      if (response.data.error) {
        console.log(response.data.messages)
      } else {
        setInvoiceToMatchEstimate();
        setFlash({ type: "success", message: "You have approved the estimate." })
      }
    }).catch(function (error) {
      console.error(error);
    });
  }

  const setInvoiceToMatchEstimate = () => {
    const form = new FormData();
    form.append("bs_job_id", id);
    const options = {
      method: "POST",
      url: 'add_estimate_to_invoice',
      data: form
    };

    axios.request(options).then(function (response) {
      if (response.data.error) {
        console.log(response.data);
      } else {
        getJob();
        setFlash({ type: "success", message: "Updated!" });
      }
    }).catch(function (error) {
      console.error(error);
    });
  }

  // show this form if the job is waiting to be paid or if job is picked up but not paid
  const showPaymentForm = () => {
    if (job.state === "awaiting_payment" || (job.estimate && job.cost && !job.paid)) {
      return <PaymentForm jobId={id} />
    }
  }

  const showPaymentHistory = () => {
    const paymentList = []
    if (job.payments && job.payments.length > 0) {
      job.payments.forEach(payment => {
        paymentList.push(
          <ul key={payment.uuid}>
            <li>Type: {payment.type === "FundCodePayment"? "FundCode":payment.type}</li>
            <li>Amount: ${makeNumberCurrency(payment.amount)}</li>
            <li>Paid on: {payment.pretty_paid_at}</li>
            <li>Paid By: {payment.paid_by.display_name} | {payment.paid_by.duid}</li>
          </ul>
        )
      })

      return <>
        <div className="tab-content-titles">
            <h3>Payments</h3>
            <span className="badge text-bg-success">Received</span>
        </div>
        {paymentList}</>
    }
  }

  const showEstimate = () => {
    
    let approveEstimate;
    let estimate = <h3>Estimate</h3>;
    if (job.estimate) { estimate = <h3>{`Estimate Total: $${makeNumberCurrency(job.estimate)}`}</h3> }
    if (job.state === "awaiting_estimate_approval" || (job.estimate && !job.estimate_approved_by)) {
      approveEstimate = <button onClick={sendEstimateApproval} type="button" className="btn btn-primary" data-cy="view-job-approve-estimate">Approve Estimate</button>
    }

    return <>
      <div className="tab-content-titles">
          {estimate}
          <div>
            {job.estimate ? <span className="badge text-bg-warning" data-cy="view-job-badge">Available</span> : <span className="badge text-bg-secondary" data-cy="view-job-badge">Not Available Yet</span>}
            {job.estimate ? <a title="Download Estimate" className="btn" href={`${process.env.REACT_APP_API_HOST}/jobs/${id}/estimate_pdf`} download><FontAwesomeIcon icon={faFileArrowDown} /></a> : ''}
          </div>
      </div>

      {job.estimate ? <Table colName={LineItemTitle} data={estimateItems} /> : ''}
      {approveEstimate}

    </>
  }

  const showInvoice = () => {
    let cost = <h3>Invoice</h3>;
    if (job.cost) { cost = <h3>{`Invoice Total: $${makeNumberCurrency(job.cost)}`}</h3> }
    return <>
      <div className="tab-content-titles">
          {cost}
          <div>
            {job.cost ? <span className="badge text-bg-warning">Available</span> : <span className="badge text-bg-secondary">Not Available Yet</span>}
            {job.cost ? <a title="Download Invoice" className="btn" href={`${process.env.REACT_APP_API_HOST}/jobs/${id}/invoice_pdf`} download><FontAwesomeIcon icon={faFileArrowDown} /></a> : ''}
          </div>
      </div>
      {job.cost ? <Table colName={LineItemTitle} data={invoiceItems} /> : ''}</>
  }

  const updateAutoApproveValue = (remove) => {
    if (remove) {
      Job.setAutoApprove(job.id, null);
      setAutoApproveValue(0);
      window.alert('You will disable auto-approved estimates.');
    } else {
      if (/^([0-9]+)$/.test(autoApproveValue)) {
        Job.setAutoApprove(job.id, autoApproveValue);
        window.alert(`Your estimates will be automatically approved if the total is less than $${autoApproveValue}`);
      } else {
        setAutoApproveValue(0);
        window.alert('Please input a number');
      }
    }
  }

  const toggleAutoApprove = (e) => {
    if (e.target.checked) {
      setAutoApproveSelected(true);
    } else {
      setAutoApproveSelected(false);
      updateAutoApproveValue(true);
    }
  }

  const automaticApproval = () => {
    return <div className="form-check">
      <input onChange={(e) => {toggleAutoApprove(e)}} className="form-check-input" type="checkbox" defaultChecked={autoApproveSelected} />
      <p className="d-inline"><b>Auto-approve estimate</b> if total is less than: </p>
      <div className="row">
        <div className="col-auto">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">$</span>
            </div>
            <input onChange={(e) => {console.log(e.target.value); setAutoApproveValue(e.target.value)}} type="text" className="form-control" disabled={!autoApproveSelected} value={autoApproveSelected ? (autoApproveValue) : ('')} aria-describedby="basic-addon1"/>
          </div>
        </div>
        <div className="col-auto">
          <button onClick={() => {updateAutoApproveValue(false)}} className="btn btn-primary btn-sm" disabled={!autoApproveSelected}>Update</button>
        </div>
      </div>
    </div>
  }

  const costs = () => {
    return <>
      {showEstimate()}
      {automaticApproval()}
      {showInvoice()}
      {showPaymentForm()}
      {showPaymentHistory()}
    </>
  }

  const calculateCostTab = (jobState) => {
    if (jobState === "awaiting_payment" || jobState === "awaiting_estimate_approval") {
      setCostTabState(<span>Cost/Payment <FontAwesomeIcon style={ { color: "green" }} icon={faExclamation} /></span>)
    }
  }

  const pageContent = () => {
    if(Object.keys(job).length > 0){
      return <div className="container-lg wrapper jobs job-details">
        <div className="main">
          <h1 data-cy="job-name">{job.name} </h1>
          <h2 className="small-title" data-cy="job-id-state">Job # {id} - {job.state ? formatJobState(job) : "Status Unknown"}</h2>
            <div className="mobile-tabs">
              <Tabs dataCy="job-tabs" tabs={[<FontAwesomeIcon icon={faInfo} />, <FontAwesomeIcon icon={faFile} />, <FontAwesomeIcon icon={faCreditCard} />, <FontAwesomeIcon icon={faUserGroup} />]} content={[detail(), files(), costs(), collaborators()]} />
            </div>
            <div className="desktop-tabs">
              <Tabs dataCy="job-tabs" tabs={["Details", "Files", costTabState, "Collaborators"]} content={[detail(), files(), costs(), collaborators()]} />
            </div>
            <div>
              <ChatView jobId={id} />
            </div>
        </div>
      </div>
    }
  }

  return <>
    {pageContent()}
  </>
}