import { useEffect, useState } from "react";
import Table from "../../common/Table";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { Material } from "../../models/material";

let colName = ["#", "Name", "Updated At", "Display", "Action"];
let names = ["id", "name", "updated_at", "toggle"];

export default function MaterialPage() {
  let navigate = useNavigate();
  const routeChange = (route) => {
    navigate(route);
  }
  const [materials, setMaterials] = useState([])

  const generateToggle = (material) => {
    return <div className="form-check form-switch">
      <input onChange={() => {updateMaterialDisplay(material)}} className="form-check-input" type="checkbox" role="switch" id="display" defaultChecked={material.display} />
    </div>
  }

  const updateMaterialDisplay = (material) => {
    Material.toggle_display(material.id);
  }

  const getMaterials = () => {
    Material.all().then((response) => {
      if (response.response) {
        console.log(response);
      } else {
        console.log(response);
        response.data.forEach((m) => m['toggle'] = generateToggle(m))
        setMaterials(response.data);
      }
    });
  }

  useEffect(() => {
    getMaterials();
  }, [])

  return <>
    <h1>Print Materials</h1>
    <Button className="btn btn-primary admin-addon-btn" dataCy='additional-fields' label="Edit Additional Fields" onClick={() => routeChange('additional_fields')} />
    <br />
    <Button className="btn btn-primary admin-addon-btn" dataCy='create-material' label="Add Material" onClick={() => routeChange('./createMaterial')} />
    <Table dataCy="material-table" colName={colName} names={names} data={materials} button={true} />
  </>
}