import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../common/Button";
import Table from "../common/Table";
import axios from "axios";
import { UserContext } from "../user-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import Pagination from "../common/Pagination";
import { formatJobState } from "../helpers";


let colName = ["#", "Name", "Date Create", "Status", "Action"];
let names = ["id", "name", "pretty_created_at", "state"];

export default function JobsPage() {
  const { user, setUser } = useContext(UserContext);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const limit = 10;

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/createJob`;
    navigate(path);
  }

  const [jobData, setJobData] = useState([])

  const getJobCount = () => {
    axios.get(`jobs/?client_id=${user.id}&count=1`)
      .then(res => {
        setCount(res.data);
      })
      .catch(err => {
        console.log(err);
      })
  }

  const getJobs = () => {
    axios.get(`jobs/?client_id=${user.id}&limit=${limit}&offset=${offset}&archived=false`)
      .then(res => {
        console.log("JOBS", res.data);
        res.data.forEach(row => {
          row["state"] = formatJobState(row)
        })
        setJobData(res.data)
      })
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(() => {
    console.log('user', user)
    if (user) {
      getJobCount();
      getJobs();
    }
  }, [user, offset]) // Only re-run the effect if user changes

  const jobsTable = () => {
    if (jobData) {
      return <Table colName={colName} names={names} data={jobData} button={true} />
    }
  }

  return <div className="container wrapper jobs">
    <div className="main">
      <h1>My Jobs</h1>
        <Button className="btn btn-primary" dataCy="my-job-submit-a-job" label="Submit a Job" onClick={routeChange} />
        {jobsTable()}
        <Pagination offset={offset} limit={limit} count={count} setOffset={setOffset} />
    </div>
  </div>
}