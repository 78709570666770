import { useEffect, useState } from "react";
import Table from "../../common/Table";
import axios from "axios";
import { Button } from "../../common/Button";
import { useNavigate } from "react-router-dom";
import { Machine } from "../../models/machine";

let colName = ["#", "Name", "Updated At", "Availability", "Action"];
let names = ["id", "name", "updated_at", "toggle"];

export default function PrinterPage() {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `./createPrinter`;
    navigate(path);
  }
  const [printers, setPrinters] = useState([]);

  const generateToggle = (machine) => {
    return <div className="form-check form-switch">
      <input onChange={() => {updateMachineAvailability(machine)}} className="form-check-input" type="checkbox" role="switch" id="availability" defaultChecked={machine.availability} />
    </div>
  }

  const updateMachineAvailability = (machine) => {
    Machine.toggle_availability(machine.id);
  }

  const getPrinters = () => {
    Machine.all().then((response) => {
      let tempPrinters = [];
      if (response.response) {
          console.log(response);
      } else {
        console.log(response);
        response.data.forEach((m) => m['toggle'] = generateToggle(m))
        setPrinters(response.data);
      }
    });
  }

  useEffect(() => {
    getPrinters();
  }, [])

  return <>
    <h1>Machines</h1>
    <Button className="btn btn-primary admin-addon-btn" label="Add Machine" dataCy="create-machine" onClick={routeChange} />
    <Table dataCy="printer-table" colName={colName} names={names} data={printers} button={true} />
  </>
}