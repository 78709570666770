import { useContext, useEffect, useState } from "react";
import { FlashContext } from "../../flash-context";
import { Blurb } from "../../models/blurb";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import Table from "../../common/Table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export default function ContentPage() {
  const [blurbs, setBlurbs] = useState([])
  const { flash, setFlash } = useContext(FlashContext);

  let colName = ["#", "Name", "Display", "Updated At", "Action"];
  let names = ["id", "name", "active", "pretty_updated_at"];

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `./createContent`;
    navigate(path);
  }

  const getBlurbs = () => {
    Blurb.all().then((response) => {
      if (response.response) {
          console.log(response);
      } else {
        response.data.data.forEach((b) => {
          b["active"] = b.active ? <FontAwesomeIcon icon={faCheck} />:""
        })
        setBlurbs(response.data.data);
      }
    });
  }

  useEffect(() => {
    getBlurbs();
  }, [])

  return <>
    <h1>Content Editor</h1>
    <Button dataCy="create-content" className="btn btn-primary admin-addon-btn" label="Add Content" onClick={routeChange} />
    <Table dataCy="content-table" colName={colName} names={names} data={blurbs} button={true} />
  </>
}