import './user.css';
import logo from '../assets/illustrations/logobluesmith_white.svg';

export default function Footer() {
    const hasFooter = <>
        <footer>
            <img
                src={logo}
                alt='bluesmith logo'
                className='iconLogo'
            />
            {/* <p> Questions? Comments? Email us at <a href="https://outlook.office365.com/owa/calendar/Bluesmith3DPrintingConsultation@ProdDuke.onmicrosoft.com/bookings/">bluesmith@duke.edu</a></p> */}
            <p> Questions? Comments? Email us at <a href="mailto:bluesmith@duke.edu?subject=Questions about Bluesmith">bluesmith@duke.edu</a></p>
            <p><a href="https://oarc.duke.edu/privacy/duke-university-privacy-statement/">Duke Privacy Statement</a></p>
        </footer>
    </>

    const hasNoFooter = <>
        
    </>

    if (onAdminSide()){
        return hasNoFooter;
    }
    else {
        return hasFooter;
    }


    // checking the url to know if we are on the admin side
    function onAdminSide() {
        var pageUrl = window.location.href;
        var result = pageUrl.match(/admin/g);
        return result;
    }  
}

