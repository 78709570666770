import { useState, useEffect, useContext} from "react";
import { FlashContext } from '../flash-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons';
import { Payment } from '../models/payment';
import axios from "axios";


export default function PaymentForm(props) {
  const [builtAttrs, setBuiltAttrs] = useState([])
  const [fundCode, setFundCode] = useState(null)
  const { flash, setFlash } = useContext(FlashContext);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);
  const [showPayWithCredit, setShowPayWithCredit] = useState(false);

  useEffect(() => {
    getFormAttrsNew();
  }, [])

  useEffect(() => {
    //console.log('i fire once');
    getFormAttrs();
  }, [])

  const buildFormAttrs = (theAttrs) => {
    for (const key in theAttrs){
      setBuiltAttrs(current => [...current,  <input type="hidden" key={key} name={key} value={theAttrs[key]}/>])
    }
  }

  const checkFundCode = (event) => {
    const input = event.target.value
    if (input.length >=7 ){
      validateFundCode(input);
    }else(
      setFundCode(false)
    )
  }

  const cyberSourceForm = () => {
    if(builtAttrs.length > 0){
      return <>
        <form id="payment_confirmation" action={process.env.REACT_APP_CYBERSOURCE_URL} method="post">
          {builtAttrs}
          <button type="submit" className="btn btn-primary" data-cy="pay-with-credit-button"><FontAwesomeIcon icon={faCreditCard} /> Pay With Credit</button>
        </form>      
      </>
    }
  }

  // returns true if the fundCode has expired
  // returns false if the fundCode has not expired or if the fundCode has no valid_to value
  const checkDate = () => {
    if (fundCode?.valid_to) {
      const today = new Date();
      const expiration = new Date(fundCode?.valid_to);
      return expiration < today
    } else {
      return false
    }
  }

  const formatDate = (date) => {
    const month = ["January","February","March","April","May","June",
                  "July","August","September","October","November",
                  "December"];
    const dateArr = date.split("-");
    const monthName = month[parseInt(dateArr[1])-1]
    const dateString = monthName + " " + dateArr[2] + ", " + dateArr[0]
    return dateString
  }

  const fundCodeDetails = () => {
    if (fundCode?.valid) {
      return <>
      <p>{fundCode?.description}</p>
      <p>{fundCode?.display}</p>
      </>
    } else if (checkDate()) {
      return <>
      <p style={{color: "red", fontWeight: "bold"}}> Fund code expired on {formatDate(fundCode?.valid_to)}! </p>
      </>
    } else if (fundCode?.valid === false) {
      return <>
      <p style={{color: "red", fontWeight: "bold"}} data-cy="view-job-pay-fundcode-check"> Fund code is invalid! </p>
      </>
    }
  }

  const fundCodeForm = () => {
      return <>
          <br />
          {/* <div className="input-group mb-3"> */}
          <div>
          <button onClick={payWithFundCode} type="submit" data-cy="pay-with-fundcode-button" className="btn btn-primary"
            disabled = {!fundCode?.valid}
            style = {{backgroundColor: fundCode?.valid ? "" : "grey"}}>
            <FontAwesomeIcon icon={faMoneyCheckDollar} /> 
              Pay With Fund Code
          </button>
            {/* <input  type="text" className="form-control" placeholder="e.g. 1234567" aria-label="Fund Code" aria-describedby="fund-code" /> */}
          </div>

          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="fund-code" className="col-form-label">Fund Code</label>
            </div>
            <div className="col-auto">
              <input onChange={checkFundCode} type="text" id="fund-code" className="form-control" aria-describedby="fundCodeHelpLine"/>
            </div>
            {/* <div className="col-auto">
              <span id="fundCodeHelpLine" className="form-text">
                7 Digit Fund Code
              </span>
            </div> */}
          </div>
      </>
  }

  const getFormAttrs = () => {
    const options = {method: 'GET',url: `payments/${props.jobId}/attrs`};
    axios.request(options).then(function (response) {
      buildFormAttrs(response.data);
    }).catch(function (error) {
      console.error(error);
      setFlash({type: "danger",  message: "Unable to process credit card payment."})
    });
  }

  const payWithFundCode = () => {
    if (fundCode?.valid && allowSubmit){
      setAllowSubmit(false);
      const options = {method: 'GET',url: `payments/${props.jobId}/fund_code?fund_code=${fundCode.code}`};
      axios.request(options).then(function (response) {
        setFlash({ type: "success", message: "Fund code payment successful!" });
        // window.location.reload(false) 
      }).catch(function (error) {
        setAllowSubmit(true);
        console.error(error);
        setFlash({type: "danger",  message: "Unable to process fund code payment."})
      });
    } else if (!allowSubmit){
        setFlash({type: "danger",  message: "Fund code already used."})
    }
      else {
      setFlash({type: "danger",  message: "Must provide valid fund code if paying with fund code."})
    }
  }


  const validateFundCode = (search) => {
    console.log("searchingFundCode", fundCode)

    const options = {method: 'GET',url: `payments/validate?fund_code=${search}`};
    axios.request(options).then(function (response) {
      console.log("RESPONSE", response)

      // set fundCode regardless of whether fundCode is valid
      setFundCode(response?.data?.data)
      
      if(response?.data?.error) {
        setFlash({type: "danger",  message: response?.data?.messages})
      }
    }).catch(function (error) {
      console.error("ERROR", error);
      setFlash({type: "danger",  message: "Unable to process fund code payment."})
    });
  }

  const getFormAttrsNew = () => {
    Payment.getJobAttrs(props.jobId).then(response => {
      const attrs = response.data;
      const invisibleForm = Object.keys(attrs).map(a => {return <input type="hidden" key={a} name={a} value={attrs[a]}/>})
      setBuiltAttrs(invisibleForm)
    }).catch(err => {
      console.log(err);
      setFlash({ type: "danger", message: "Unable to process credit card payments." });
    })
  }

  const fundCodePayment = () => {
    return <div className="row">
      <div className="col-sm">
        <input onChange={(e) => validateAndUpdate(e)} placeholder="Fund Code (xxx-xxxx)" type="text" className="form-control" id="fund-code" aria-describedby="fundCodeHelpLine"/>
        {validationMessage}
      </div>
      <div className="col-sm">
        <button onClick={(e) => payWithFundCodeNew(e)} type="submit" data-cy="pay-with-fundcode-button" className="btn btn-primary btn-sm mb-0">
          <FontAwesomeIcon icon={faMoneyCheckDollar} /> Pay With Fund Code
        </button>
        <button className="btn btn-sm mb-1 btn-outline-secondary" onClick={(e) => {setShowPayWithCredit(!showPayWithCredit)}} >No fund code?</button>
        {payWithCredit()}
      </div>
    </div>
  }

  const validateAndUpdate = (e) => {
    const inputValue = e.target.value;
    setFundCode(inputValue);
    if (inputValue.length >= 7) {
      Payment.getFundCodeData(inputValue).then(response => {
        const details = response.data.data;
        if (details.valid) {
          setValidationMessage(<span className="badge text-bg-success">
            {details.description}: {details.display}
          </span>);
          setAllowSubmit(true);
        } else if (details.valid && !details.valid_to) {
          const today = new Date();
          const expiration = new Date(details.valid_to);
          if (expiration < today) {
            setValidationMessage(<span className="badge text-bg-danger">
              Fund code expired on {expiration.toDateString()}
            </span>);
          } 
        } else {
          setValidationMessage(<span className="badge text-bg-danger">
            Please use a valid fund code
          </span>);
          setAllowSubmit(false);
        }
      }).catch(err => {
        console.log(err);
        setValidationMessage(<span className="badge text-bg-danger">
          Unable to reach fund code validation service
        </span>);
        setAllowSubmit(false);
      })
    } else if (validationMessage) {
      setValidationMessage(null);
    }
  }

  const payWithFundCodeNew = (e) => {
    e.preventDefault();
    if (allowSubmit) {
      Payment.fundCodePayment(props.jobId, fundCode).then(response => {
        setFlash({ type: "success", message: "Fund code payment successful!" });
      }).catch(err => {
        console.log(err);
        setFlash({ type: "danger", message: "Unable to reach fund code validation service" });
      })
    } else {
      setFlash({ type: "danger", message: "Please use a valid fund code" });
    }
    return 0;
  }

  const payWithCredit = () => {
    return showPayWithCredit ? (<div>
      <form id="payment_confirmation" action={process.env.REACT_APP_CYBERSOURCE_URL} method="post">
        {builtAttrs}
        <h6>If you have access to a Duke fund code, please use it. Duke Purchasing Cards should not be used to pay for Bluesmith jobs.</h6>
        <button type="submit" className="btn btn-link float-end btn-sm text-nowrap" data-cy="pay-with-credit-button">If you have a personal credit card, click here</button>
      </form>
      </div>) : ('')
  }

  return <>
    {fundCodePayment()}
{/*     {cyberSourceForm()}
    {fundCodeForm()}
    {fundCodeDetails()} */}
  </>
}